import React from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Header, headerHeight } from '../Header';
import { Footer } from '../Footer';
import { Box } from '@mui/material';

const Layout = () => {
  return (
    <React.Fragment>
      <Header />
      <Box minHeight={`calc(100vh - ${headerHeight})`}>
        <ScrollRestoration />
        <Outlet />
      </Box>
      <Footer />
    </React.Fragment>
  )
};

export default Layout;

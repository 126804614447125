import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { ProductEntry } from '../types';

export const getProductEntries = async (id: string, params?: URLSearchParams) => {
  return await apiClient
    .get<ProductEntry[]>(apiRoutes.products.productEntries(id, params))
    .then((response) => response.data);
};

const useGetProductEntries = (
  id: string,
  params?: URLSearchParams,
  options?: UseQueryOptions<ProductEntry[], Error, ProductEntry[]>
) => {
  return useQuery({
    queryKey: apiRoutes.products.productEntries(id, params),
    queryFn: async () => await getProductEntries(id, params),
    ...options,
  });
};

export default useGetProductEntries;

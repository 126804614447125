import React from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

export interface FieldProps extends BoxProps {
  title: string;
  titleTypographyProps?: TypographyProps;
  value: string;
  valueTypographyProps?: TypographyProps;
}

const Field = ({
  title,
  titleTypographyProps,
  value,
  valueTypographyProps,
  ...boxProps
}: FieldProps) => {
  return (
    <Box {...boxProps}>
      <Typography variant="subtitle2" {...titleTypographyProps}>
        {title}
      </Typography>
      <Typography variant="caption" {...valueTypographyProps}>
        {value}
      </Typography>
    </Box>
  );
};

export default Field;


const apiRoutes = {
  auctions: {
    auction: (id: string) => `/auctions/${id}`,
    auctions: (params: URLSearchParams) => `/auctions?${params}`,
    count: '/auctions/count',
  },
  items: {
    items: '/items',
    count: '/items/count',
  },
  products: {
    products: '/products',
    product: (id: string) => `/products/${id}`,
    productEntries: (id: string, params?: URLSearchParams) => `/products/${id}/product-entries?${params}`,
    count: '/products/count',
    views: (id: string) => `/products/${id}/views`,
    trending: '/products/trending',
    mostViewed: '/products/most-viewed',
    recentlyAdded: '/products/recently-added',
  },
  statistics: {
    statistics: (type: string) => `/statistics/${type}`,
    newest: (type: string) => `/statistics/${type}/newest`,
    oldest: (type: string) => `/statistics/${type}/oldest`,
  },
};

export default apiRoutes;

import {
  ButtonBase,
  Card,
  CardContent,
  CardProps,
  Divider,
  dividerClasses,
  SxProps,
  Theme,
  Typography
} from '@mui/material';
import { Auction } from '../../types';
import { getRarityColor } from '../../utils';
import { Link as RouterLink } from 'react-router-dom';
import { toAbsDisplayNumber } from '../../../../utils';

interface AuctionCardProps extends Partial<CardProps> {
  auction: Auction;
}

const AuctionCard = ({
  auction,
  ...cardProps
}: AuctionCardProps) => {
  const rarityColor = getRarityColor(auction.decodedItemBytes.rarity);

  const sx: SxProps<Theme> = {
    ['&:hover']: {
      borderColor: `${rarityColor}4A`,
      backgroundColor: `${rarityColor}0A`,
      [`& .${dividerClasses.root}`]: {
        borderColor: `${rarityColor}4A`,
      },
    },
  };

  return (
    <Card variant="outlined" sx={sx} {...cardProps}>
      <ButtonBase
        component={RouterLink}
        to={`/auctions/${auction.id}`}
        sx={{ display: 'block', textAlign: 'initial' }}
      >
        <Typography variant="caption" display="block" color="text.secondary" mx={2} my={0.5}>
          {new Date(auction.timestamp).toLocaleDateString()}
        </Typography>
        <Divider />
        <CardContent>
          <Typography variant="subtitle1" fontWeight={600} noWrap>
            {auction.decodedItemBytes.name}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {toAbsDisplayNumber(auction.price, 0)} coins
          </Typography>
          <Typography variant="caption" color={rarityColor} fontWeight={500} noWrap>
            {auction.decodedItemBytes.rarity}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Card>
  )
};

export default AuctionCard;

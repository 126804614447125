import React from 'react';
import { Skeleton, SkeletonProps } from '@mui/material';

export interface SkeletonWrapperProps extends SkeletonProps {
  loading: boolean;
}

const SkeletonWrapper = ({
  loading,
  ...skeletonProps
}: SkeletonWrapperProps) => {
  return loading
    ? <Skeleton {...skeletonProps} />
    : <React.Fragment>{skeletonProps.children}</React.Fragment>;
};

export default SkeletonWrapper;

import { AuctionCard, AuctionCardSkeleton, useGetAuctionCount, useGetAuctions } from '../../features/auctions';
import {
  Box,
  Container,
  FormControl, InputLabel,
  MenuItem,
  Select,
  TextField, Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { toAbsDisplayNumber } from '../../utils';
import { useUrlParam } from '../../hooks';
import { useDebounce } from 'use-debounce';
import { useHomeLoaderData } from '../home';

const AuctionListPage = () => {
  const { value: searchTerm, setValue: setSearchTerm } = useUrlParam<string>('search', '');
  const { value: rarity, setValue: setRarity } = useUrlParam<string | null>('rarity', null);

  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 500);

  const params = new URLSearchParams({
    limit: '52',
    itemNameLike: debouncedSearchTerm,
  });
  rarity && params.set('rarity', rarity);

  const { data: auctions } = useGetAuctions(params);
  // const { data: auctionCount } = useGetAuctionCount();
  const { auctionCount } = useHomeLoaderData("index");

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Hypixel Skyblock's previously sold auctions
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" mb={4}>
        There are currently {toAbsDisplayNumber(auctionCount, 0)} auctions recorded in skycoins.
      </Typography>
      <Box display="flex" gap={2}>
        <TextField
          placeholder={`Search ${toAbsDisplayNumber(auctionCount, 0)} auctions`}
          fullWidth
          sx={{ mb: 4 }}
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />
        <FormControl sx={{ width: 300 }}>
          <InputLabel id="rarity-select">Rarity</InputLabel>
          <Select
            labelId="rarity-select"
            label="Rarity"
            value={rarity}
            onChange={(event) => setRarity(event.target.value)}
          >
            <MenuItem value="COMMON">Common</MenuItem>
            <MenuItem value="UNCOMMON">Uncommon</MenuItem>
            <MenuItem value="RARE">Rare</MenuItem>
            <MenuItem value="EPIC">Epic</MenuItem>
            <MenuItem value="LEGENDARY">Legendary</MenuItem>
            <MenuItem value="MYTHIC">Mythic</MenuItem>
            <MenuItem value="DIVINE">Divine</MenuItem>
            <MenuItem value="SPECIAL">Special</MenuItem>
            <MenuItem value="VERY SPECIAL">Very Special</MenuItem>
            <MenuItem value="ULTIMATE">Ultimate</MenuItem>
            <MenuItem value="ADMIN">Admin</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid2 container spacing={2}>
        {auctions ? (
          auctions.map((auction) => (
            <Grid2 key={auction.id} md={3} sm={6} xs={12}>
              <AuctionCard key={auction.id} auction={auction} />
            </Grid2>
          ))
        ): (
          Array.from({ length: 52 }).map((_, index) => (
            <Grid2 key={index} md={3} sm={6} xs={12}>
              <AuctionCardSkeleton key={index} />
            </Grid2>
          ))
        )}
        {auctions && auctions.length === 0 && (
          <Grid2>
            <Typography variant="caption" color="text.secondary">
              We couldn't find anything&#8212;try searching something else.
            </Typography>
          </Grid2>
        )}
      </Grid2>
    </Container>
  );
};

export default AuctionListPage;

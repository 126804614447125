import { Box, BoxProps, Stack, Typography, TypographyProps } from '@mui/material';
import React from 'react';

export interface HeaderFieldProps extends BoxProps {
  title: string;
  titleTypographyProps?: TypographyProps;
  value: string;
  valueTypographyProps?: TypographyProps;
  children?: React.ReactNode;
}

const HeaderField = ({
  title,
  titleTypographyProps,
  value,
  valueTypographyProps,
  children,
  ...boxProps
}: HeaderFieldProps) => {
  return (
    <Box p={3} {...boxProps}>
      <Stack spacing={2}>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          noWrap
          {...titleTypographyProps}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          fontWeight={600}
          noWrap
          {...valueTypographyProps}
        >
          {value}
        </Typography>
      </Stack>
      {children}
    </Box>
  );
};

export default HeaderField;

import { useEffect, useState } from 'react';

const useSkeleton = (loading: boolean, duration: number) => {
  const [showSkeleton, setShowSkeleton] = useState<boolean>(loading);

  useEffect(() => {
    if (!loading) {
      const timeout = setTimeout(() => setShowSkeleton(false), duration);
      return () => clearTimeout(timeout);
    }
  }, [loading, duration])

  return showSkeleton;
};

export default useSkeleton;
import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Statistic } from '../types';

export const getStatistics = async (type: string) => {
  return await apiClient
    .get<Statistic[]>(apiRoutes.statistics.statistics(type))
    .then((response) => response.data);
};

const useGetStatistics = (
  type: string,
  options?: UseQueryOptions<Statistic[], Error, Statistic[]>
) => {
  return useQuery({
    queryKey: apiRoutes.statistics.statistics(type),
    queryFn: async () => await getStatistics(type),
    ...options,
  });
};

export default useGetStatistics;

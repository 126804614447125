
export const toDisplayNumber = (num: number, decimals?: number): string => {
  const sign = num >= 0 ? '+' : '';
  return sign + num.toLocaleString(undefined, {
    minimumFractionDigits: decimals ?? 2,
    maximumFractionDigits: decimals ?? 2,
  });
};

export const toAbsDisplayNumber = (num: number, decimals?: number): string => {
  return Math.abs(num)
    .toLocaleString(undefined, {
      minimumFractionDigits: decimals ?? 2,
      maximumFractionDigits: decimals ?? 2,
    });
};

export const toCompactDisplayNumber = (num: number): string => {
  return Intl.NumberFormat('en', {
    notation: 'compact'
  }).format(num);
}

export const toDisplayName = (str: string): string => {
  return str.toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
};

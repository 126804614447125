import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Product } from '../types';

const getMostViewedProducts = async () => {
  return await apiClient
    .get<Product[]>(apiRoutes.products.mostViewed)
    .then((response) => response.data);
};

const useGetMostViewedProducts = (
  options?: UseQueryOptions<Product[], Error, Product[]>
) => {
  return useQuery({
    queryKey: apiRoutes.products.mostViewed,
    queryFn: getMostViewedProducts,
    ...options,
  });
};

export default useGetMostViewedProducts;

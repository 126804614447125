import { apiClient, apiRoutes } from '../../../api';
import { useMutation } from 'react-query';

const putProductView = async (id: string) => {
  return await apiClient.put(apiRoutes.products.views(id))
    .then((response) => response.data);
}

const usePutProductView = (id: string) => {
  return useMutation({
    mutationKey: apiRoutes.products.views(id),
    mutationFn: async () => await putProductView(id),
  });
};

export default usePutProductView;

import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  LoaderFunctionArgs,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';
import {
  AuctionListPage, auctionLoader,
  AuctionPage, FavoriteListPage, homeLoader,
  ProductListPage, productLoader,
  ProductPage, statisticsLoader,
  StatisticsPage,
} from './pages';
import { AppProvider, Layout } from './components';

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        id="index"
        loader={homeLoader}
        element={<Layout />}
      >
        <Route
          index
          element={<Navigate to="/products" replace />}
        />
        <Route path="products">
          <Route
            index
            element={<ProductListPage />}
          />
          <Route
            path=":productId"
            id="product"
            // loader={(args: LoaderFunctionArgs) => productLoader(args)}
            element={<ProductPage />}
          />
        </Route>
        <Route path="auctions">
          <Route
            index
            element={<AuctionListPage />}
          />
          <Route
            path=":auctionId"
            id="auction"
            // loader={(args: LoaderFunctionArgs) => auctionLoader(args)}
            element={<AuctionPage />}
          />
        </Route>
        <Route path="statistics">
          <Route
            index
            id="statistics"
            // loader={statisticsLoader}
            element={<StatisticsPage />}
          />
        </Route>
        <Route path="favorites">
          <Route
            index
            element={<FavoriteListPage />}
          />
        </Route>
      </Route>
    )
  );

  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
};

export default App;

import { Box, BoxProps, Button, Stack, TextField, Typography } from '@mui/material';
import React from 'react';

export interface CommentInputProps extends BoxProps {
  disabled?: boolean;
}

const CommentInput = ({ disabled, ...boxProps }: CommentInputProps) => {
  return (
    <Box {...boxProps}>
      <Typography variant="subtitle1" fontWeight={500} mb={2} noWrap>
        Discussion
      </Typography>
      <TextField
        multiline
        minRows={2}
        size="small"
        fullWidth
        placeholder="Leave a comment"
        disabled={disabled}
      />
      <Stack direction="row" spacing={1} justifyContent="flex-end" mt={1}>
        <Button disabled={disabled}>
          Clear
        </Button>
        <Button disabled={disabled}>
          Submit
        </Button>
      </Stack>
    </Box>
  );
};

CommentInput.defaultProps = {
  disabled: false,
};

export default CommentInput;
import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Statistic } from '../types';

export const getNewestStatistic = async (type: string) => {
  return await apiClient
    .get<Statistic>(apiRoutes.statistics.newest(type))
    .then((response) => response.data);
};

const useGetNewestStatistic = (
  type: string,
  options?: UseQueryOptions<Statistic, Error, Statistic>
) => {
  return useQuery({
    queryKey: apiRoutes.statistics.newest(type),
    queryFn: async () => await getNewestStatistic(type),
    ...options,
  });
};

export default useGetNewestStatistic;

import { useState } from 'react';
import { Product, ProductDataGrid } from '../../features/products';
import { getFavoriteAuctions, getFavoriteProducts } from '../../features/favorites';
import { Auction, AuctionCard } from '../../features/auctions';
import { Container, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

const FavoriteListPage = () => {
  const [products, setProducts] = useState<Product[]>(getFavoriteProducts());
  const [auctions, setAuctions] = useState<Auction[]>(getFavoriteAuctions());

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" mb={4}>
        Favorite Products
      </Typography>
      {products && products.length !== 0 && (
        <ProductDataGrid isLoading={false} products={products} />
      )}
      {products && products.length === 0 && (
        <Typography variant="caption" color="text.secondary">
          You don't have any favorite products just yet&#8212;go favorite some!
        </Typography>
      )}
      <Divider sx={{ mt: 4 }} />
      <Typography variant="h5" fontWeight="bold" gutterBottom my={4}>
        Favorite Auctions
      </Typography>
      <Grid2 container spacing={2}>
        {auctions.map((auction) => (
          <Grid2 key={auction.id} md={3} sm={6} xs={12}>
            <AuctionCard auction={auction} />
          </Grid2>
        ))}
      </Grid2>
      {auctions && auctions.length === 0 && (
        <Typography variant="caption" color="text.secondary">
          You don't have any favorite auctions just yet&#8212;go favorite some!
        </Typography>
      )}
    </Container>
  );
};

export default FavoriteListPage;

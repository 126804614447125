import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useMemo } from 'react';
import { useStatisticsLoaderData } from '../../../../pages';
import { useGetStatistics } from '../../api';

export interface ProductCapChartProps extends Highcharts.Options { }

const ProductCapChart = ({ ...highchartsOptions }: ProductCapChartProps) => {
  // const { productBuyCap, productSellCap } = useStatisticsLoaderData('statistics');
  const { data: productBuyCap } = useGetStatistics('product-buy-cap');
  const { data: productSellCap } = useGetStatistics('product-sell-cap');

  const buyCapData = useMemo(() => productBuyCap?.map((stat) => ([
    new Date(stat.createDate).getTime(),
    stat.numericValue,
  ])), [productBuyCap]);

  const sellCapData = useMemo(() => productSellCap?.map((stat) => ([
    new Date(stat.createDate).getTime(),
    stat.numericValue,
  ])), [productSellCap]);

  const options: Highcharts.Options = {
    series: [
      {
        name: 'Product Buy Cap',
        data: buyCapData,
        type: 'line',
      },
      {
        name: 'Product Sell Cap',
        data: sellCapData,
        type: 'line',
      },
    ],
    tooltip: {
      split: true,
    },
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    scrollbar: {
      enabled: false,
    },
    chart: {
      height: 400,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={options}
    />
  );
};

export default ProductCapChart;
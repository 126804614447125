import { CommentInput, Field } from '../../components';
import {
  AuctionCard,
  AuctionChart,
  AuctionHeader,
  getRarityColor, useGetAuction, useGetAuctions,
} from '../../features/auctions';
import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import {
  getAveragePriceExcludingSelf, getRarities, getScatterDataSelected, getTrendLineData
} from '../../features/auctions/utils/rarityData';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useAuctionLoaderData } from './index';

const chartOptions = {
  marker: {
    enabled: false
  },
  states: {
    hover: {
      lineWidth: 0
    },
  },
  enableMouseTracking: false,
  showInLegend: false,
};

const AuctionPage = () => {
  const { auctionId } = useParams();

  const { data: auction } = useGetAuction(
    auctionId!,
    {
      enabled: !!auctionId,
      keepPreviousData: true,
    },
  );
  const { data: auctions } = useGetAuctions(
    new URLSearchParams({
      itemName: auction?.decodedItemBytes.name ?? '',
      limit: '250',
    }),
    {
      enabled: !!auction,
      keepPreviousData: true,
    },
  );

  if (!auction || !auctions) return <></>;

  // const { auction, auctions } = useAuctionLoaderData('auction');

  const averagePrice = getAveragePriceExcludingSelf(auctions, auction);
  const rarities = getRarities(auctions);

  const scatterData = rarities.map((rarity) => ({
    id: `${rarity}-scatter`,
    name: rarity,
    data: getScatterDataSelected(auctions, rarity, auction),
    type: 'scatter',
  }));
  const lineData = rarities.map((rarity) => ({
    id: `${rarity}-line`,
    name: rarity,
    data: getTrendLineData(auctions, rarity),
    type: 'line',
    ...chartOptions,
  }));

  const mergedData = [...scatterData, ...lineData];

  return (
    <Grid2
      container
      columns={14}
      sx={{
        '--Grid-borderWidth': '1px',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
      }}
    >
      <Grid2 lg={3} md={4} sm={14} xs={14}>
        <AuctionHeader
          auction={auction}
          price={auction.price}
          averagePrice={averagePrice}
        />
        <Divider />
        <Field
          title="Rarity"
          value={auction.decodedItemBytes.rarity}
          valueTypographyProps={{
            color: getRarityColor(auction.decodedItemBytes.rarity),
            fontWeight: 500,
          }}
          p={3}
        />
        <Divider />
        <Field
          title="Sell Date"
          value={new Date(auction.timestamp).toLocaleDateString()
            + ' ' + new Date(auction.timestamp).toLocaleTimeString()}
          p={3}
        />
        <Divider />
        <Field
          title="Lore"
          value={auction.decodedItemBytes.lore}
          valueTypographyProps={{
            whiteSpace: 'pre',
          }}
          p={3}
        />
      </Grid2>
      <Grid2 lg={8} md={6} sm={14} xs={14}>
        <Container maxWidth="lg" disableGutters>
          <Box flexGrow={1} minWidth={0}>
            <Box borderBottom="1px solid" borderColor="divider">
              <Tabs value="comparison" sx={{ px: 3 }}>
                <Tab label="Comparison" value="comparison" />
              </Tabs>
            </Box>
            <Box p={3}>
              <AuctionChart
                // @ts-ignore
                series={mergedData}
                colors={rarities.map((rarity) => getRarityColor(rarity))}
              />
              <Typography variant="h6" my={3}>
                Discover Similar Auctions
              </Typography>
              <Grid2 container spacing={2} >
                {auctions.slice(0, 8).map((auction) => (
                  <Grid2 key={auction.id} xl={3} lg={6} md={12} sm={6} xs={12}>
                    <AuctionCard key={auction.id} auction={auction} />
                  </Grid2>
                ))}
              </Grid2>
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Grid2 lg={3} md={4} sm={14} xs={14} p={3}>
        <CommentInput disabled mb={3} />
        <Divider sx={{ mb: 3, mx: -3 }} />
        <Typography variant="caption" color="text.secondary">
          Comments are currently disabled.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default AuctionPage;

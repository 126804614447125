import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useUrlParam = <T extends string | number | null>(name: string, defaultValue: T) => {
  const [searchParams, setSearchParams] = useSearchParams()

  let initialParamValue: string | number | undefined

  if (searchParams.has(name)) {
    if (typeof defaultValue === 'number') {
      initialParamValue = parseInt(searchParams.get(name)!)
    } else {
      initialParamValue = searchParams.get(name)!
    }
  }

  const [value, setValue] = useState<T>(initialParamValue as T ?? defaultValue)

  useEffect(() => {
    if (value === defaultValue || value === null) {
      searchParams.delete(name)
    } else {
      searchParams.set(name, String(value))
    }
    setSearchParams(searchParams, { replace: true })
  }, [value])

  return { value, setValue }
}

export default useUrlParam;

import { Product } from '../types';

export const exampleProducts: Product[] = [
  {
    "id": "FINE_JADE_GEM",
    "createDate": "2024-04-23T03:25:02.607174Z",
    "lastUpdated": "2024-04-23T05:14:01.138744Z",
    "name": "FINE_JADE_GEM",
    "item": {
      "id": "FINE_JADE_GEM",
      "createDate": 0,
      "material": "SKULL_ITEM",
      "name": "Fine Jade Gemstone",
      "category": 'null',
      "tier": "RARE",
      "npcSellPrice": 19200,
      "skin": "ewogICJ0aW1lc3RhbXAiIDogMTYxODA4Mzg3NjAxNywKICAicHJvZmlsZUlkIiA6ICI0ZTMwZjUwZTdiYWU0M2YzYWZkMmE3NDUyY2ViZTI5YyIsCiAgInByb2ZpbGVOYW1lIiA6ICJfdG9tYXRvel8iLAogICJzaWduYXR1cmVSZXF1aXJlZCIgOiB0cnVlLAogICJ0ZXh0dXJlcyIgOiB7CiAgICAiU0tJTiIgOiB7CiAgICAgICJ1cmwiIDogImh0dHA6Ly90ZXh0dXJlcy5taW5lY3JhZnQubmV0L3RleHR1cmUvYjI4ZjFjMGM1MDkyZTEyZDMzNzcwZGY0NWM1ODQ1YTk2MTA4ODYwMzliMzRhYmU5M2ExNmM1ZTk0MmRmYzhlNCIKICAgIH0KICB9Cn0"
    },
    "latestProductEntry": {
      "id": "74fa88b0-4b73-48e9-9444-2f3d90da6e21",
      "createDate": "2024-04-23T05:14:01.138744Z",
      "buyPrice": 30779.7,
      "sellPrice": 27107.70038655962,
      "buyVolume": 23695,
      "sellVolume": 158915,
      "buyOrders": 50,
      "sellOrders": 30,
      "buyMovingWeek": 683712,
      "sellMovingWeek": 944454
    },
    "referenceProductEntry": {
      "id": "d1f6bf95-6451-4a32-872f-6ad0de61601c",
      "createDate": "2024-04-23T03:25:02.607174Z",
      "buyPrice": 30782.326227678575,
      "sellPrice": 6185.215115434878,
      "buyVolume": 16876,
      "sellVolume": 152313,
      "buyOrders": 39,
      "sellOrders": 11,
      "buyMovingWeek": 680297,
      "sellMovingWeek": 939548
    },
  },
  {
    "id": "CARROT_ITEM",
    "createDate": "2024-04-23T03:25:02.513174Z",
    "lastUpdated": "2024-04-23T05:16:00.958333Z",
    "name": "CARROT_ITEM",
    "item": {
      "id": "CARROT_ITEM",
      "createDate": 0,
      "material": "CARROT_ITEM",
      "name": "Carrot",
      "category": 'null',
      "tier": 'null',
      "npcSellPrice": 3,
      "skin": 'null'
    },
    "latestProductEntry": {
      "id": "34f880b8-f6e0-4b0a-bf63-5395cc9331ea",
      "createDate": "2024-04-23T05:16:00.958333Z",
      "buyPrice": 3.410128916096507,
      "sellPrice": 2,
      "buyVolume": 3429395,
      "sellVolume": 519913,
      "buyOrders": 103,
      "sellOrders": 9,
      "buyMovingWeek": 57604999,
      "sellMovingWeek": 250898338
    },
    "referenceProductEntry": {
      "id": "442b762f-b465-4743-8893-0446de0adcc9",
      "createDate": "2024-04-23T03:25:02.513174Z",
      "buyPrice": 3.802171983088242,
      "sellPrice": 1.2,
      "buyVolume": 3573777,
      "sellVolume": 421715,
      "buyOrders": 93,
      "sellOrders": 8,
      "buyMovingWeek": 57203576,
      "sellMovingWeek": 249640905
    },
  },
  {
    "id": "LOG_2:1",
    "createDate": "2024-04-23T03:25:01.948176Z",
    "lastUpdated": "2024-04-23T05:17:00.601696Z",
    "name": "LOG_2:1",
    "item": {
      "id": "LOG_2:1",
      "createDate": 0,
      "material": "LOG_2",
      "name": "Dark Oak Wood",
      "category": 'null',
      "tier": 'null',
      "npcSellPrice": 2,
      "skin": 'null'
    },
    "latestProductEntry": {
      "id": "305b367d-a506-4cc6-94f8-9697ce10f476",
      "createDate": "2024-04-23T05:17:00.601696Z",
      "buyPrice": 7.9,
      "sellPrice": 1.6,
      "buyVolume": 1716003,
      "sellVolume": 1536311,
      "buyOrders": 73,
      "sellOrders": 24,
      "buyMovingWeek": 5344963,
      "sellMovingWeek": 27942767
    },
    "referenceProductEntry": {
      "id": "aad3f232-b06e-4d51-a992-f2fb289a810c",
      "createDate": "2024-04-23T03:25:01.949174Z",
      "buyPrice": 7.777970031031526,
      "sellPrice": 1.4,
      "buyVolume": 1751753,
      "sellVolume": 230069,
      "buyOrders": 81,
      "sellOrders": 5,
      "buyMovingWeek": 5275606,
      "sellMovingWeek": 27784337
    },
  },
];
import { Card, CardContent, Divider, Skeleton, Typography } from '@mui/material';

const AuctionCardSkeleton = () => {
  return (
    <Card variant="outlined">
      <Skeleton sx={{ mx: 2, my: 0.25 }}>
        <Typography variant="caption">
          Placeholder
        </Typography>
      </Skeleton>
        <Divider />
        <CardContent>
          <Skeleton>
            <Typography variant="subtitle1" noWrap>
              Placeholder Placeholder
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="subtitle2" noWrap>
              Placeholder Placeholder
            </Typography>
          </Skeleton>
          <Skeleton>
            <Typography variant="caption" noWrap>
              Placeholder
            </Typography>
          </Skeleton>
        </CardContent>
    </Card>
  )
};

export default AuctionCardSkeleton;

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useMemo } from 'react';
import { useStatisticsLoaderData } from '../../../../pages';
import { useGetStatistics } from '../../api';

export interface AuctionSaleChartProps extends Highcharts.Options { }

const AuctionCountChart = ({ ...highchartsOptions }: AuctionSaleChartProps) => {
  // const { auctionCount } = useStatisticsLoaderData('statistics'); {
    const { data: auctionCount } = useGetStatistics('auction-count');

  const data = useMemo(() => auctionCount?.map((stat) => ([
    new Date(stat.createDate).getTime(),
    stat.numericValue,
  ])), [auctionCount]);

  const options: Highcharts.Options = {
    series: [
      {
        name: 'Auction Sale Count',
        data: data,
        type: 'line',
      },
    ],
    tooltip: {
      split: true,
    },
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    scrollbar: {
      enabled: false,
    },
    chart: {
      height: 400,
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={options}
    />
  );
};

export default AuctionCountChart;
import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Product } from '../types';

export const getProduct = async (id: string) => {
  return await apiClient
    .get<Product>(apiRoutes.products.product(id))
    .then((response) => response.data);
};

const useGetProduct = (
  id: string,
  options?: UseQueryOptions<Product, Error, Product>
) => {
  return useQuery({
    queryKey: apiRoutes.products.product(id),
    queryFn: async () => await getProduct(id),
    ...options,
  });
};

export default useGetProduct;

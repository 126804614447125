
export const getRarityColor = (rarity: string): string => {
  switch (rarity.toUpperCase()) {
    case 'COMMON': return 'rgba(0, 0, 0, 0.87)';
    case 'UNCOMMON': return '#55FF55';
    case 'RARE': return '#5555FF';
    case 'EPIC': return '#AA00AA';
    case 'LEGENDARY': return '#FFAA00';
    case 'MYTHIC': return '#FF55FF';
    case 'DIVINE': return '#55FFFF';
    case 'SPECIAL': return '#FF5555';
    case 'VERY SPECIAL': return '#FF5555';
    case 'ULTIMATE': return '#AA0000';
    case 'ADMIN': return '#AA0000';
    default: return 'rgba(0, 0, 0, 0.87)';
  }
};

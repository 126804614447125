import { Box, BoxProps, styled } from '@mui/material';

export interface ColumnProps extends BoxProps {
  borderSide: 'left' | 'right';
}

const Column = styled(Box)<ColumnProps>(({ theme, borderSide }) => ({
  borderLeft: borderSide === 'left' ? '1px solid' : undefined,
  borderRight: borderSide === 'right' ? '1px solid' : undefined,
  borderColor: theme.palette.divider,
  flexGrow: 1,
  flexShrink: 0,
  width: '20%',
  maxWidth: '20%',
  minWidth: '350px',
}));

export default Column;

import React from 'react';
import {
  ProductCard,
  ProductDataGrid,
  useGetMostViewedProducts, useGetRecentlyAddedProducts,
  useGetTrendingProducts
} from '../../features/products';
import { Box, Container, Typography } from '@mui/material';
import { IconClock, IconEye, IconFlame } from '@tabler/icons-react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useGetNewestStatistic, useGetOldestStatistic } from '../../features/statistics';
import { toCompactDisplayNumber } from '../../utils';
import { PercentChange } from '../../components';
import useGetProducts from '../../features/products/api/useGetProducts';

const ProductListPage = () => {
  const { data: products, isLoading } = useGetProducts();

  const { data: productBuyCapNewest } = useGetNewestStatistic('product-buy-cap');
  const { data: productBuyCapOldest } = useGetOldestStatistic('product-buy-cap');

  const { data: trendingProducts } = useGetTrendingProducts();
  const { data: mostViewedProducts } = useGetMostViewedProducts();
  const { data: recentlyAddedProducts } = useGetRecentlyAddedProducts();

  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Hypixel Skyblock's current in-game prices
      </Typography>
      <Box display="flex" gap={0.5} mb={4} flexWrap="wrap">
        <Typography variant="subtitle2" color="text.secondary">
          The overall value of all products is {toCompactDisplayNumber(productBuyCapNewest?.numericValue || 0)} coins, a
        </Typography>
        <PercentChange
          current={productBuyCapNewest?.numericValue || 1500}
          initial={productBuyCapOldest?.numericValue || 1500}
          typographyProps={{
            variant: 'subtitle2',
            fontWeight: 600,
          }}
          loading={productBuyCapNewest === undefined || productBuyCapOldest === undefined}
        />
        <Typography variant="subtitle2" color="text.secondary">
          change all-time.
        </Typography>
      </Box>
      <Grid2 container spacing={2} mb={4}>
        <Grid2 md={4} xs={12}>
          <ProductCard
            icon={<IconFlame />}
            label="Trending"
            products={trendingProducts || []}
            loading={trendingProducts === undefined}
          />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <ProductCard
            icon={<IconEye />}
            label="Most Visited"
            products={mostViewedProducts || []}
            loading={mostViewedProducts === undefined}
          />
        </Grid2>
        <Grid2 md={4} xs={12}>
          <ProductCard
            icon={<IconClock />}
            label="Recently Added"
            products={recentlyAddedProducts || []}
            loading={recentlyAddedProducts === undefined}
          />
        </Grid2>
      </Grid2>
      <Box height={2105}>
        <ProductDataGrid
          products={products}
          isLoading={isLoading}
        />
      </Box>
    </Container>
  );
};

export default ProductListPage;

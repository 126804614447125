import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Auction } from '../types';

export const getAuction = async (id: string) => {
  return await apiClient
    .get<Auction>(apiRoutes.auctions.auction(id))
    .then((response) => response.data);
}

const useGetAuction = (
  id: string,
  options?: UseQueryOptions<Auction, Error, Auction>
) => {
  return useQuery({
    queryKey: apiRoutes.auctions.auction(id),
    queryFn: async () => await getAuction(id),
    ...options,
  });
};

export default useGetAuction;

import { Auction } from '../../auctions';
import { Product } from '../../products';

export const addFavoriteAuction = (auction: Auction): void => {
  const item = 'favoriteAuctions';
  createFavoritesIfNotExists(item);

  const favorites: Auction[] = JSON.parse(localStorage.getItem(item)!);
  favorites.push(auction);
  localStorage.setItem(item, JSON.stringify(favorites));
};

export const addFavoriteProduct = (product: Product): void => {
  const item = 'favoriteProducts';
  createFavoritesIfNotExists(item);

  const favorites: Product[] = JSON.parse(localStorage.getItem(item)!);
  favorites.push(product);
  localStorage.setItem(item, JSON.stringify(favorites));
};

export const saveFavoriteAuctions = (auctions: Auction[]): void => {
  const item = 'favoriteAuctions';
  localStorage.setItem(item, JSON.stringify(auctions));
};

export const saveFavoriteProducts = (products: Product[]): void => {
  const item = 'favoriteProducts';
  localStorage.setItem(item, JSON.stringify(products));
};

const createFavoritesIfNotExists = (item: string): void => {
  if (localStorage.getItem(item) === null) {
    localStorage.setItem(item, JSON.stringify([]));
  }
};

export const getFavoriteAuctions = (): Auction[] => {
  const item = 'favoriteAuctions';
  createFavoritesIfNotExists(item);
  const auctions: Auction[] = JSON.parse(localStorage.getItem(item)!);
  return auctions;
};

export const getFavoriteProducts = (): Product[] => {
  const item = 'favoriteProducts';
  createFavoritesIfNotExists(item);
  const products: Product[] = JSON.parse(localStorage.getItem(item)!);
  return products;
};

export const resetFavoriteProducts = (): void => {
  resetFavorites('favoriteProducts');
};

const resetFavorites = (item: string): void => {
  localStorage.setItem(item, JSON.stringify([]));
};
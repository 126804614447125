import useGetProducts from '../../api/useGetProducts';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { FavoriteIcon, PercentChange } from '../../../../components';
import { Product } from '../../types';
import { Avatar, Box, Typography } from '@mui/material';
import { getPercentChange, toDisplayName, toAbsDisplayNumber } from '../../../../utils';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { PreviewChart } from '../ProductPreviewChart';
import { exampleProducts, getTexture } from '../../utils';
import { ProductDataGridCell } from './index';
import { getFavoriteProducts, saveFavoriteProducts } from '../../../favorites';

const rowHeight: number = 80;
const columnHeaderHeight: number = 50;

export interface ProductDataGridProps {
  products?: Product[];
  isLoading: boolean;
  pageSize?: number;
  borderBottom?: boolean;
}

const ProductDataGrid = ({ products, isLoading, pageSize, borderBottom }: ProductDataGridProps) => {
  const navigate = useNavigate();

  // @ts-ignore
  const placeholderProducts: Product[] = Array.from({ length: 50 }).map((_, index) => ({
    ...exampleProducts[0],
    id: index
  }));

  const [favorites, setFavorites] = useState<Product[]>(getFavoriteProducts());

  useEffect(() => {
    saveFavoriteProducts(favorites);
  }, [favorites]);

  const favoriteIds: string[] = useMemo(() => favorites.map((favorite) => favorite.id), [favorites]);

  const handleFavoriteClick = (product: Product) => {
    if (favoriteIds.includes(product.id)) {
      setFavorites(favorites.filter((favorite) => favorite.id !== product.id));
    } else {
      setFavorites([...favorites, product]);
    }
  };

  const columns: GridColDef[] = useMemo(() => ([
    {
      field: 'favorite',
      type: 'actions',
      getActions: (params: GridRowParams<Product>) => [
        <GridActionsCellItem
          label="Favorite"
          icon={(
            <FavoriteIcon
              favorite={favoriteIds.includes(params.row.id)}
              size={16}
            />
          )}
          onClick={() => handleFavoriteClick(params.row)}
        />
      ],
      width: 32,
      resizable: false,
    },
    {
      field: 'popularity',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">#</Typography>
      ),
      renderCell: (params: GridRenderCellParams<Product>) => (
        <ProductDataGridCell loading={isLoading}>
          <Typography variant="subtitle2" fontWeight={400}>
            {params.api.getAllRowIds().indexOf(params.id) + 1}
          </Typography>
        </ProductDataGridCell>
      ),
      width: 32,
      disableReorder: true,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      resizable: false,
    },
    {
      field: 'name',
      headerName: 'Product',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          Product
        </Typography>
      ),
      valueGetter: (name, product: Product) =>  (
        product.item ? product.item.name : toDisplayName(name)
      ),
      renderCell: (params: GridRenderCellParams<Product>) => (
        <ProductDataGridCell loading={isLoading} gap={1}>
          <Avatar sx={{ width: 24, height: 24 }} src={getTexture(params.row)} />
          <Typography variant="subtitle2" fontWeight="600" noWrap>
            {params.value}
          </Typography>
        </ProductDataGridCell>
      ),
      flex: 3,
      minWidth: 240,
    },
    {
      field: 'buyPrice',
      headerName: 'Buy Price',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          Buy Price
        </Typography>
      ),
      valueGetter: (_, product: Product) => product.latestProductEntry.buyPrice,
      renderCell: (params) => (
        <ProductDataGridCell loading={isLoading}>
          <Typography variant="subtitle2" fontWeight="500" noWrap>
            {toAbsDisplayNumber(params.value)}
          </Typography>
        </ProductDataGridCell>
      ),
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'buyPrice12hr',
      headerName: '12h %',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          12h %
        </Typography>
      ),
      valueGetter: (_, product) => getPercentChange(
        product.referenceProductEntry.buyPrice,
        product.latestProductEntry.buyPrice
      ),
      renderCell: (params) => (
        <ProductDataGridCell loading={isLoading}>
          <PercentChange
            initial={params.row.referenceProductEntry.buyPrice}
            current={params.row.latestProductEntry.buyPrice}
            typographyProps={{
              variant: 'subtitle2',
              fontWeight: 500,
              noWrap: true,
            }}
          />
        </ProductDataGridCell>
      ),
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'buyPrice12hrChart',
      headerName: 'Last 12 hrs',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          Last 12h
        </Typography>
      ),
      valueGetter: (_, product) => getPercentChange(
        product.referenceProductEntry.buyPrice,
        product.latestProductEntry.buyPrice
      ),
      renderCell: (params) => (
        <ProductDataGridCell
          loading={isLoading}
          skeletonProps={{
            variant: 'rectangular',
            width: '100%'
          }}
        >
          <Box height={50} flexGrow={1} flexShrink={0}>
            {!isLoading && <PreviewChart product={params.row} type="buyPrice" />}
          </Box>
        </ProductDataGridCell>
      ),
      flex: 2,
      minWidth: 160,
    },
    {
      field: 'none',
      headerName: '',
      disableReorder: true,
      disableColumnMenu: true,
      disableExport: true,
      sortable: false,
      resizable: false,
      width: 32,
    },
    {
      field: 'sellPrice',
      headerName: 'Sell Price',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          Sell Price
        </Typography>
      ),
      valueGetter: (_, product) => product.latestProductEntry.sellPrice,
      renderCell: (params) => (
        <ProductDataGridCell loading={isLoading}>
          <Typography variant="subtitle2" fontWeight="500" noWrap>
            {toAbsDisplayNumber(params.value)}
          </Typography>
        </ProductDataGridCell>
      ),
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'sellPrice12hr',
      headerName: '12h %',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          12h %
        </Typography>
      ),
      valueGetter: (_, product) => getPercentChange(
        product.referenceProductEntry.sellPrice,
        product.latestProductEntry.sellPrice
      ),
      renderCell: (params) => (
        <ProductDataGridCell loading={isLoading}>
          <PercentChange
            initial={params.row.referenceProductEntry.sellPrice}
            current={params.row.latestProductEntry.sellPrice}
            typographyProps={{
              variant: 'subtitle2',
              fontWeight: 500,
              noWrap: true,
            }}
          />
        </ProductDataGridCell>
      ),
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'sellPrice12hrChart',
      headerName: 'Last 12 hrs',
      renderHeader: () => (
        <Typography variant="caption" fontWeight="bold">
          Last 12h
        </Typography>
      ),
      valueGetter: (_, product) => getPercentChange(
        product.referenceProductEntry.sellPrice,
        product.latestProductEntry.sellPrice
      ),
      renderCell: (params) => (
        <ProductDataGridCell
          loading={isLoading}
          skeletonProps={{
            variant: 'rectangular',
            width: '100%'
          }}
        >
          <Box height={50} flexGrow={1} flexShrink={0}>
            {!isLoading && <PreviewChart product={params.row} type="sellPrice" />}
          </Box>
        </ProductDataGridCell>
      ),
      flex: 2,
      minWidth: 160,
    },
  ]), [isLoading, favorites]);

  const initialState: GridInitialStateCommunity = {
    pagination: {
      paginationModel: {
        pageSize: pageSize || 25
      },
    },
    sorting: {
      sortModel: [
        {
          field: 'sellPrice12hr',
          sort: 'desc',
        },
      ],
    },
  };

  return (
    <DataGrid
      rows={products || placeholderProducts}
      columns={columns}
      initialState={initialState}
      rowHeight={rowHeight}
      columnHeaderHeight={columnHeaderHeight}
      onRowClick={(params: GridRowParams<Product>) => {
        if (isLoading) return;
        navigate(`/products/${params.row.id}`)
      }}
      sx={{
        borderLeft: 0,
        borderRight: 0,
        borderBottom: borderBottom ? undefined : 0,
        borderRadius: 0,
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer'
        },
        '& ::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      pageSizeOptions={[]}
    />
  )
};

export default ProductDataGrid;

import { ResponsiveLineCanvas } from '@nivo/line';
import { Product } from '../../types';
import React, { useMemo, useRef } from 'react';
import { useGetProductEntries } from '../../api';
import { SkeletonWrapper } from '../../../../components';

export interface PreviewChartProps  {
  product: Product;
  type: 'buyPrice' | 'sellPrice';
}

const ProductPreviewChart = ({ product, type }: PreviewChartProps) => {
  // store this date globally on first launch... we're never able to utilize the cache
  const date = useRef(new Date(Date.now() - 12 * 60 * 60 * 1000));

  const { data: productEntries, isLoading } = useGetProductEntries(product.id, new URLSearchParams({
    fromDate: new Date(Math.round(date.current.getTime() / 1000 / 60 / 10) * 1000 * 60 * 10).toISOString(),
  }));

  const data = useMemo(() => {
    if (!productEntries) return [];
    return productEntries.map((productEntry) => ({
      x: productEntry.createDate,
      y: productEntry[type],
    }))
  }, [productEntries, type])

  const color = product.latestProductEntry[type] - product.referenceProductEntry[type] >= 0 ? 'green' : 'red';

  return (
    <SkeletonWrapper
      variant="rectangular"
      height="100%"
      width="100%"
      loading={isLoading}
    >
      <ResponsiveLineCanvas
        data={[{ id: 'buyPrice', color: color, data: data }]}
        curve="linear"
        enablePoints={false}
        enableGridX={false}
        enableGridY={false}
        lineWidth={2.5}
        isInteractive={false}
        axisLeft={null}
        axisBottom={null}
        axisRight={null}
        axisTop={null}
        margin={{
          top: 2,
          bottom: 2,
        }}
        colors={(d) => d.color}
        enableArea
        areaOpacity={0.05}
        // without these props, warnings show in the console
        enableCrosshair={false}
        crosshairType="bottom-left"
      />
    </SkeletonWrapper>
  )
};

export default ProductPreviewChart;

import { Container, Typography } from '@mui/material';
import { AuctionCountChart, ProductCapChart } from '../../features/statistics';

const StatisticsPage = () => {
  return (
    <Container sx={{ py: 4 }}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Auctions sold over time
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" mb={4}>
        See how many auctions are sold every minute.
      </Typography>
      <AuctionCountChart />
      <Typography variant="h5" fontWeight="bold" gutterBottom mt={4}>
        Product buy and sell cap
      </Typography>
      <Typography variant="subtitle2" color="text.secondary" mb={4}>
        Compare the total value of all products available to buy vs. available for sale.
      </Typography>
      <ProductCapChart />
    </Container>
  );
};

export default StatisticsPage;
import { Product } from '../types';

export interface ProductValues {
  initialPrice: number;
  currentPrice: number;
  volume: number;
  orders: number;
  movingWeek: number;
}

export const getProductValues = (
  product: Product,
  type: 'buy' | 'sell'
): ProductValues => {
  switch (type) {
    case 'buy':
      return {
        initialPrice: product.referenceProductEntry.buyPrice,
        currentPrice: product.latestProductEntry.buyPrice,
        volume: product.latestProductEntry.buyVolume,
        orders: product.latestProductEntry.buyOrders,
        movingWeek: product.latestProductEntry.buyMovingWeek,
      }
    case 'sell':
      return {
        initialPrice: product.referenceProductEntry.sellPrice,
        currentPrice: product.latestProductEntry.sellPrice,
        volume: product.latestProductEntry.sellVolume,
        orders: product.latestProductEntry.sellOrders,
        movingWeek: product.latestProductEntry.sellMovingWeek,
      }
  }
};
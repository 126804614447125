import { Box, Typography } from '@mui/material';
import logo from '../../images/logo.png';

const Logo = () => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box component="img" src={logo} width={28} height={28} />
      <Typography variant="h5" mr={6} sx={{ fontFamily: "'Jersey 25'", userSelect: 'none' }}>
        skycoins
      </Typography>
    </Box>
  );
};

export default Logo;

import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Auction } from '../types';

export const getAuctions = async (params: URLSearchParams) => {
  return await apiClient
    .get<Auction[]>(apiRoutes.auctions.auctions(params))
    .then((response) => response.data);
}

const useGetAuctions = (
  params: URLSearchParams,
  options?: UseQueryOptions<Auction[], Error, Auction[]>
) => {
  return useQuery({
    queryKey: apiRoutes.auctions.auctions(params),
    queryFn: async () => await getAuctions(params),
    ...options,
  });
};

export default useGetAuctions;

import { useQuery } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';

export const getProductCount = async () => {
  return await apiClient
    .get<number>(apiRoutes.products.count)
    .then((response) => response.data);
}

const useGetProductCount = () => {
  return useQuery({
    queryKey: apiRoutes.products.count,
    queryFn: getProductCount,
    refetchInterval: 1000 * 60,
  });
};

export default useGetProductCount;

import { Auction } from '../../types';
import { FavoriteIcon, HeaderField, HeaderFieldProps, PercentChange } from '../../../../components';
import { toAbsDisplayNumber } from '../../../../utils';
import { Button, Stack } from '@mui/material';
import { IconCopy, IconStar } from '@tabler/icons-react';
import { useEffect, useMemo, useState } from 'react';
import { getFavoriteAuctions, saveFavoriteAuctions } from '../../../favorites';

export interface AuctionHeaderProps extends Partial<HeaderFieldProps> {
  auction: Auction;
  price: number;
  averagePrice: number;
}

const AuctionHeader = ({
  auction,
  price,
  averagePrice,
  ...headerFieldProps
}: AuctionHeaderProps) => {
  const [favorites, setFavorites] = useState<Auction[]>(getFavoriteAuctions());
  const isFavorite = useMemo(() => !!favorites.find((favorite) => favorite.id === auction.id), [favorites, auction]);

  useEffect(() => {
    saveFavoriteAuctions(favorites);
  }, [favorites]);

  const handleFavoriteClick = (auction: Auction) => {
    if (isFavorite) {
      setFavorites(favorites.filter((favorite) => favorite.id !== auction.id));
    } else {
      setFavorites([...favorites, auction]);
    }
  };

  return (
    <HeaderField
      title={auction.decodedItemBytes.name}
      value={`${toAbsDisplayNumber(auction.price, 0)} coins`}
      {...headerFieldProps}
    >
      <PercentChange
        current={price}
        initial={averagePrice}
        typographyProps={{
          fontWeight: 500,
        }}
      />
      <Stack spacing={1.5} mt={3}>
        <Button
          variant="ghost"
          color={isFavorite ? 'secondary' : 'inherit'}
          sx={{ justifyContent: 'flex-start' }}
          startIcon={<FavoriteIcon favorite={isFavorite} />}
          onClick={() => handleFavoriteClick(auction)}
        >
          {isFavorite ? 'Favorited' : 'Add to favorites'}
        </Button>
        <Button
          variant="ghost"
          color="inherit"
          sx={{ justifyContent: 'flex-start' }}
          startIcon={<IconCopy size="1rem" />}
        >
          Copy share link
        </Button>
      </Stack>
    </HeaderField>
  );
};

export default AuctionHeader;

import { IconCaretDownFilled, IconCaretUpFilled } from '@tabler/icons-react';
import React from 'react';

export interface DirectionIconProps {
  change: number;
  size?: string | number;
}

const DirectionIcon = ({ change, size }: DirectionIconProps) => {
  return (
    <>
      {change >= 0 && <IconCaretUpFilled size={size} style={{ flexShrink: 0 }} />}
      {change < 0 && <IconCaretDownFilled size={size} style={{ flexShrink: 0 }} />}
    </>
  )
};

DirectionIcon.defaultProps = {
  size: 16,
};

export default DirectionIcon;

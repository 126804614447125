import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Product } from '../types';

export const getProducts = async () => {
  return await apiClient
    .get<Product[]>(apiRoutes.products.products)
    .then((response) => response.data);
};

const useGetProducts = (
  options?: UseQueryOptions<Product[], Error, Product[]>
) => {
  return useQuery({
    queryKey: apiRoutes.products.products,
    queryFn: getProducts,
    ...options,
  });
};

export default useGetProducts;

import {
  Box,
  Button, Drawer, IconButton,
  InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { IconChartDots, IconChartHistogram, IconGavel, IconMenu2, IconSearch, IconStar } from '@tabler/icons-react';
import { Stat, StatProps } from '../Stat';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png';
import { FavoriteIcon } from '../FavoriteButton';
import { useHomeLoaderData } from '../../pages';
import { useState } from 'react';

export const headerHeight: string = '64px';

const Header = () => {
  const { pathname } = useLocation();

  const {
    itemCount,
    productCount,
    auctionCount,
    productBuyCapNewest,
    productBuyCapOldest,
    productSellCapNewest,
    productSellCapOldest,
  } = useHomeLoaderData("index");

  const statProps: Partial<StatProps> = {
    compactValue: true,
    gap: 0.5,
    typographyProps: {
      variant: 'caption',
      color: 'text.secondary',
      fontWeight: 500,
      noWrap: true,
    },
  };

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Box
        display={{ md: 'block', xs: 'none' }}
        borderBottom="1px solid"
        borderColor="divider"
      >
        <Toolbar variant="dense">
          <Box display="flex" gap={2}>
            <Stat
              label="Items"
              value={itemCount}
              {...statProps}
            />
            <Stat
              label="Products"
              value={productCount}
              {...statProps}
            />
            <Stat
              label="Auctions"
              value={auctionCount}
              {...statProps}
            />
            <Stat
              label="Product Buy Cap"
              value={productBuyCapNewest.numericValue}
              percentChangeProps={{
                initial: productBuyCapOldest.numericValue,
                current: productBuyCapNewest.numericValue,
                size: 14,
              }}
              {...statProps}
            />
            <Stat
              label="Product Sell Cap"
              value={productSellCapNewest.numericValue}
              percentChangeProps={{
                initial: productSellCapOldest.numericValue,
                current: productSellCapNewest.numericValue,
                size: 14,
              }}
              {...statProps}
            />
          </Box>
          <Box flexGrow={1} />
          <Box display="flex" gap={1}>
            <Button
              variant="outlined"
              size="small"
              disabled
            >
              Log in
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled
            >
              Sign up
            </Button>
          </Box>
        </Toolbar>
      </Box>
      <Paper
        sx={{
          height: headerHeight,
          borderBottom: '1px solid',
          borderColor: 'divider',
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
        elevation={0}
      >
        <Toolbar sx={{ height: headerHeight }}>
          <IconButton
            sx={{
              display: { md: 'none', xs: 'inherit' },
              mr: { sm: 2, xs: 1 },
            }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            <IconMenu2 size="1rem" />
          </IconButton>
          <Drawer open={open} onClose={() => setOpen(false)}>
            <List sx={{ width: 250 }}>
              <ListItem>
                <ListItemButton component={RouterLink} to="/products" onClick={() => setOpen(false)}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <IconChartHistogram size="1.25rem" />
                  </ListItemIcon>
                  <ListItemText primary="Products" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component={RouterLink} to="/auctions" onClick={() => setOpen(false)}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <IconGavel size="1.25rem" />
                  </ListItemIcon>
                  <ListItemText primary="Auctions" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component={RouterLink} to="/statistics" onClick={() => setOpen(false)}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <IconChartDots size="1.25rem" />
                  </ListItemIcon>
                  <ListItemText primary="Statistics" />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton component={RouterLink} to="/favorites" onClick={() => setOpen(false)}>
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <IconStar size="1.25rem" />
                  </ListItemIcon>
                  <ListItemText primary="Favorites" />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
          <Box component="img" src={logo} width={28} height={28} mr={1} />
          <Typography variant="h5" mr={6} sx={{ fontFamily: "'Jersey 25'", userSelect: 'none' }}>
            skycoins
          </Typography>
          <Box display={{ md: 'flex', xs: 'none' }} gap={3}>
            <Button
              component={RouterLink}
              to="/products"
              color={pathname.startsWith('/products') ? 'primary' : 'inherit'}
            >
              Products
            </Button>
            <Button
              component={RouterLink}
              to="/auctions"
              color={pathname.startsWith('/auctions') ? 'primary' : 'inherit'}
            >
              Auctions
            </Button>
            <Button
              component={RouterLink}
              to="/statistics"
              color={pathname.startsWith('/statistics') ? 'primary' : 'inherit'}
            >
              Statistics
            </Button>
          </Box>
          <Box flexGrow={1} />
          <Button
            component={RouterLink}
            to="/favorites"
            sx={{ fontWeight: 400, display: { md: 'inherit', xs: 'none' } }}
            color={pathname.startsWith('/favorites') ? 'primary' : 'inherit'}
            startIcon={<FavoriteIcon favorite={pathname.startsWith('/favorites')} />}
          >
            Favorites
          </Button>
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch size={16} />
                </InputAdornment>
              )
            }}
            size="small"
            sx={{ ml: 2 }}
            disabled
          />
        </Toolbar>
      </Paper>
    </>
  )
};

export default Header;
import {
  Avatar, Box,
  Card,
  CardContent,
  CardProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon, ListItemText, Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { Product } from '../../types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toDisplayName } from '../../../../utils';
import { PercentChange } from '../../../../components';
import { getTexture } from '../../utils';

export interface ProductCardProps extends Partial<CardProps> {
  icon: React.ReactNode;
  label: string;
  products: Product[];
  loading: boolean;
}

const ProductCard = ({ icon, label, products, loading }: ProductCardProps) => {
  return (
    <Card variant="outlined">
      <CardContent sx={{ pb: 1 }}>
        <Stack direction="row" spacing={1}>
          {icon}
          <Typography fontWeight="bold">
            {label}
          </Typography>
        </Stack>
      </CardContent>
      <List>
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <ListItem key={index} disableGutters disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <Skeleton variant="circular">
                    <Avatar sx={{ width: 24, height: 24}} />
                  </Skeleton>
                </ListItemIcon>
                <ListItemText
                  primary={<Skeleton />}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
        ) : (
          products.slice(0, 3).map((product) => (
            <ListItem key={product.id} disableGutters disablePadding>
              <ListItemButton component={RouterLink} to={`/products/${product.id}`}>
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <Avatar sx={{ width: 24, height: 24 }} src={getTexture(product)} />
                </ListItemIcon>
                <ListItemText
                  primary={product.item?.name || toDisplayName(product.name)}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    fontWeight: 500,
                    noWrap: true,
                  }}
                />
                <Box flexGrow={1} />
                <PercentChange
                  current={product.latestProductEntry.sellPrice}
                  initial={product.referenceProductEntry.sellPrice}
                  typographyProps={{
                    variant: 'caption',
                    fontWeight: 500,
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </Card>
  );
};

export default ProductCard;

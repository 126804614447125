import { Box, Link, Typography } from '@mui/material';
import { Logo } from '../Logo';
import Grid2 from '@mui/material/Unstable_Grid2';

const Footer = () => {
  return (
    <Box
      borderTop="1px solid"
      borderColor="divider"
      px={3}
      py={2}
    >
      <Grid2 container spacing={2}>
        <Grid2 md={3} xs={12}>
          <Logo />
        </Grid2>
        <Grid2 md={2} xs={12}>
          <Typography variant="subtitle2">
            Community
          </Typography>
          <Link>
            <Typography variant="caption">
              Discord
            </Typography>
          </Link>
        </Grid2>
        <Grid2 md={2} xs={12}>
          <Typography variant="subtitle2">
            Code
          </Typography>
          <Link>
            <Typography variant="caption">
              GitHub
            </Typography>
          </Link>
        </Grid2>
        <Grid2 md={2} xs={12}>
          <Typography variant="subtitle2">
            Created by
          </Typography>
          <Typography variant="caption">
            Copelando
          </Typography>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default Footer;

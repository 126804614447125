import { CommentInput, Field } from '../../components';
import { Box, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
import {
  getProductValues,
  ProductChart, ProductDataGrid,
  ProductHeader, useGetProduct, useGetProductEntries, useGetProducts,
  usePutProductView,
} from '../../features/products';
import { useParams } from 'react-router-dom';
import { useUrlParam } from '../../hooks';
import { toAbsDisplayNumber } from '../../utils';
import { ProductPageSkeleton, useProductLoaderData } from './index';
import React, { useEffect, useRef } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';

const ProductPage = () => {
  const { productId } = useParams();

  // const {
  //   product,
  //   products,
  //   productEntries,
  // } = useProductLoaderData("product");

  const { data: product } = useGetProduct(productId!, {
    enabled: !!productId,
    refetchInterval: 1000 * 60,
    keepPreviousData: true,
  });

  const { data: products, isLoading } = useGetProducts();

  const fromDate = useRef(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

  const { data: productEntries } = useGetProductEntries(
    productId!,
    undefined,
    // new URLSearchParams({
    //   fromDate: fromDate.current.toISOString(),
    // }),
    {
      enabled: !!productId,
      keepPreviousData: true,
    },
  );

  const {
    value: type,
    setValue: setType
  } = useUrlParam<'buy' | 'sell'>('type', 'buy');

  const putProductView = usePutProductView(product?.id ?? '');

  useEffect(() => {
    if (!product) return;
    putProductView.mutate();
  }, [product]);

  if (!product || !productEntries) return <ProductPageSkeleton />;

  const {
    initialPrice,
    currentPrice,
    volume,
    orders,
    movingWeek
  } = getProductValues(product, type);


  return (
    <Grid2
      container
      columns={14}
      sx={{
        '--Grid-borderWidth': '1px',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
      }}
    >
      <Grid2 lg={3} md={4} sm={14} xs={14}>
        <ProductHeader
          product={product}
          initialPrice={initialPrice}
          currentPrice={currentPrice}
        />
        <Divider />
        <Field
          title={type === 'buy' ? 'Buy Volume' : 'Sell Volume'}
          value={`${toAbsDisplayNumber(volume, 0)} items`}
          p={3}
        />
        <Divider />
        <Field
          title={type === 'buy' ? 'Buy Orders' : 'Sell Orders'}
          value={`${toAbsDisplayNumber(orders, 0)} orders`}
          p={3}
        />
        <Divider />
        <Field
          title={type === 'buy' ? 'Buy Moving Weekly' : 'Sell Moving Weekly'}
          value={`${toAbsDisplayNumber(movingWeek, 0)} items`}
          p={3}
        />
      </Grid2>
      <Grid2 lg={8} md={6} sm={14} xs={14}>
        <Container maxWidth="lg" disableGutters>
          <Box flexGrow={1} minWidth={0}>
            <Box borderBottom="1px solid" borderColor="divider">
              <Tabs
                value={type}
                onChange={(_, value: 'buy' | 'sell') => setType(value)}
                sx={{ px: 3 }}
              >
                <Tab label="Buy Price" value="buy" />
                <Tab label="Sell Price" value="sell" />
              </Tabs>
            </Box>
            <Box p={3}>
              <ProductChart
                productId={productId!}
                type={type}
                height={600}
              />
            </Box>
            <Typography variant="h6" px={3} mb={3}>
              Discover Other Products
            </Typography>
            <Box height={504}>
              <ProductDataGrid
                products={products}
                isLoading={false}
                pageSize={5}
              />
            </Box>
          </Box>
        </Container>
      </Grid2>
      <Grid2 lg={3} md={4} sm={14} xs={14} p={3}>
        <CommentInput disabled mb={3} />
        <Divider sx={{ mb: 3, mx: -3 }} />
        <Typography variant="caption" color="text.secondary">
          Comments are currently disabled.
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default ProductPage;
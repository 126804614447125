import Highcharts from 'highcharts/highcharts';
import HighchartsReact from "highcharts-react-official";
import { useNavigate } from 'react-router-dom';

export interface AuctionChartProps extends Highcharts.Options { }

const AuctionChart = ({ ...highchartsOptions }: AuctionChartProps) => {
  const navigate = useNavigate();

  const options: Highcharts.Options = {
    chart: {
      type: 'scatter',
      zooming: {
        type: 'xy',
      },
      height: 500,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: null
      }
    },
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    title: {
      text: '',
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: 'pointer',
        point: {
          events: {
            // @ts-ignore
            click: (event) => navigate(`/auctions/${event.point.id}`)
          }
        },
      },
    },
    ...highchartsOptions,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      options={options}
    />
  );
};

export default AuctionChart;

import { useQuery, UseQueryOptions } from 'react-query';
import { apiClient, apiRoutes } from '../../../api';
import { Statistic } from '../types';

export const getOldestStatistic = async (type: string) => {
  return await apiClient
    .get<Statistic>(apiRoutes.statistics.oldest(type))
    .then((response) => response.data);
};

const useGetOldestStatistic = (
  type: string,
  options?: UseQueryOptions<Statistic, Error, Statistic>
) => {
  return useQuery({
    queryKey: apiRoutes.statistics.oldest(type),
    queryFn: async () => await getOldestStatistic(type),
    ...options,
  });
};

export default useGetOldestStatistic;

import React from 'react';
import { useSkeleton } from '../../hooks';
import { Skeleton, SkeletonProps } from '@mui/material';

export interface DelayedSkeletonProps extends SkeletonProps {
  loading: boolean;
  delay?: number;
  children?: React.ReactNode;
}

const DelayedSkeleton = ({
  loading,
  delay,
  children,
  ...skeletonProps
}: DelayedSkeletonProps) => {
  const showSkeleton = useSkeleton(loading, delay!);

  return showSkeleton
    ? <Skeleton {...skeletonProps}>{children}</Skeleton>
    : <React.Fragment>{children}</React.Fragment>;
};

DelayedSkeleton.defaultProps = {
  delay: 1000,
};

export default DelayedSkeleton;
import { Auction } from '../types';
import * as ss from 'simple-statistics';

export const getRarities = (auctions: Auction[]): string[] => {
  // @ts-ignore
  return [...new Set(auctions.map((auction) => auction.decodedItemBytes.rarity))];
};

export const getAuctionsOfRarity = (auctions: Auction[], rarity: string): Auction[] => {
  return auctions.filter((auction) => auction.decodedItemBytes.rarity === rarity);
};

export const getAveragePrice = (auctions: Auction[]): number => {
  const sum = auctions.map((auction) => auction.price)
    .reduce((priceSum, price) => priceSum + price, 0);
  return sum / auctions.length;
};

export const getAveragePriceExcludingSelf = (auctions: Auction[], auction: Auction): number => {
  const auctionsOfRarity = getAuctionsOfRarity(auctions, auction.decodedItemBytes.rarity)
    .filter((filterAuction) => filterAuction.id !== auction.id);
  return getAveragePrice(auctionsOfRarity);
};

export const getScatterData = (auctions: Auction[], rarity: string) => {
  return getAuctionsOfRarity(auctions, rarity).map((auction) => ({
    x: new Date(auction.timestamp).getTime(),
    y: auction.price,
    id: auction.id,
  }));
};

export const getScatterDataSelected = (auctions: Auction[], rarity: string, selected: Auction) => {
  return getAuctionsOfRarity(auctions, rarity).map((auction) => ({
    x: new Date(auction.timestamp).getTime(),
    y: auction.price,
    id: auction.id,
    selected: auction.id === selected.id,
  }));
};

const getPoints = (data: any): number[][] => {
  return data.map((point: any) => [point.x, point.y]);
};

export const getTrendLineData = (auctions: Auction[], rarity: string): number[][] => {
  const data = getPoints(getScatterData(auctions, rarity));
  const { m, b } = ss.linearRegression(data);

  const minX = Math.min(...data.map(([x]) => x));
  const maxX = Math.max(...data.map(([x]) => x));

  return [
    [minX, m * minX + b],
    [maxX, m * maxX + b],
  ];
};
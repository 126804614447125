import Highcharts from 'highcharts/highstock';
import HighchartsReact from "highcharts-react-official";
import { useMemo, useRef } from 'react';
import { useProductLoaderData } from '../../../../pages';
import { useGetProductEntries } from '../../api';

export interface ProductChartProps extends Highcharts.Options {
  productId: string;
  type: 'buy' | 'sell';
  height?: string | number;
}

const ProductChart = ({
  productId,
  type,
  height,
  ...highchartsOptions
}: ProductChartProps) => {
  // const { productEntries } = useProductLoaderData("product");

  const fromDate = useRef(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

  const { data: productEntries } = useGetProductEntries(
    productId,
    undefined,
    // new URLSearchParams({
    //   fromDate: fromDate.current.toISOString(),
    // }),
    {
      keepPreviousData: true,
    },
  );

  const priceData = useMemo(() => productEntries?.map((productEntry) => ([
    new Date(productEntry.createDate).getTime(),
    type === 'buy' ? productEntry.buyPrice : productEntry.sellPrice,
  ])), [productEntries, type]);

  const volumeData = useMemo(() => productEntries?.map((productEntry) => ([
    new Date(productEntry.createDate).getTime(),
    type === 'buy' ? productEntry.buyVolume : productEntry.sellVolume,
  ])), [productEntries, type]);

  const options: Highcharts.Options = {
    series: [
      {
        name: type === 'buy' ? 'Buy Price' : 'Sell Price',
        data: priceData,
        type: 'line',
      },
      {
        name: type === 'buy' ? 'Buy Volume' : 'Sell Volume',
        data: volumeData,
        type: 'column',
        yAxis: 1,
      }
    ],
    yAxis: [
      {
        height: '80%',
        resize: {
          enabled: true,
        },
      },
      {
        top: '80%',
        height: '20%',
        offset: 0,
      },
    ],
    tooltip: {
      split: true,
    },
    time: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    scrollbar: {
      enabled: false,
    },
    // rangeSelector: {
      // selected: 0,
      // buttons: [
      //   {
      //     type: 'hour',
      //     count: 12,
      //     text: '12h',
      //     events: {
      //       click: (e) => console.log(e)
      //     },
      //   },
      //   {
      //     type: 'day',
      //     count: 1,
      //     text: '1d',
      //     events: {
      //       click: (e) => console.log(e)
      //     },
      //   },
      //   {
      //     type: 'day',
      //     count: 7,
      //     text: '1w',
      //     events: {
      //       click: (e) => console.log(e)
      //     },
      //   },
      //   {
      //     type: 'month',
      //     count: 1,
      //     text: '1m',
      //     events: {
      //       click: (e) => console.log(e)
      //     },
      //   },
      //   {
      //     type: 'all',
      //     text: 'All',
      //   },
      // ],
    // },
    chart: {
      height: height,
    },
    ...highchartsOptions,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={options}
    />
  );
};

export default ProductChart;

import bukkitIdMap from '../../../utils/bukkitIdMap';
import { Product } from '../types';
import textures from 'minecraft-textures/dist/textures/json/1.12.json';

export const getTexture = (product: Product): string => {
  if (product.item?.material === 'SKULL_ITEM') {
    return `https://mc-heads.net/head/${JSON.parse(atob(product.item?.skin!))['textures']['SKIN']['url'].split('/').pop()}`
  }

  const defaultTexture = textures.items.find((item) => item.id === 'minecraft:barrier')!.texture;
  const bookTexture = product.id.startsWith('ENCHANTMENT') ? textures.items.find((item) => item.id === 'minecraft:book')?.texture : undefined;
  const bukkitTexture = textures.items.find((item) => item.id === bukkitIdMap[product.item?.material!])?.texture;
  const mappedTexture = textures.items.find((item) => item.id === `minecraft:${product.item?.material.toLowerCase()}`)?.texture;

  return bukkitTexture ?? mappedTexture ?? bookTexture ?? defaultTexture;
};

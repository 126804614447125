import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';
import { toCompactDisplayNumber, toAbsDisplayNumber } from '../../utils';
import { PercentChange, PercentChangeProps } from '../PercentChange';
import { SkeletonWrapper } from '../Skeleton';

export interface StatProps {
  label: string | React.ReactNode;
  value: number;
  compactValue?: boolean;
  typographyProps?: TypographyProps;
  percentChangeProps?: PercentChangeProps;
  gap?: number;
  loading?: boolean;
}

const Stat = ({
  label,
  value,
  compactValue,
  typographyProps,
  percentChangeProps,
  gap,
  loading,
}: StatProps) => {
  const displayValue = compactValue ? toCompactDisplayNumber(value) : toAbsDisplayNumber(value);

  return (
    <Box display="flex" alignItems="center" gap={gap}>
      <Typography {...typographyProps}>
        {label + ': '}
        <SkeletonWrapper
          sx={{ display: 'inline' }}
          loading={loading ?? false}
        >
          <Box display="inline" color="primary.main">
            {displayValue}
          </Box>
        </SkeletonWrapper>
      </Typography>
      {percentChangeProps && (
        <PercentChange
          {...percentChangeProps}
          typographyProps={{
            ...typographyProps,
            color: undefined,
          }}
          loading={loading}
        />
      )}
    </Box>
  );
};

Stat.defaultProps = {
  compactValue: false,
  gap: 1,
  loading: false,
};

export default Stat;

import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@fontsource/jersey-25/400.css';
import { alpha, createTheme } from '@mui/material';
import { yellow } from '@mui/material/colors';

let theme = createTheme();

theme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
      },
      variants: [
        {
          props: {
            variant: 'ghost',
          },
          style: {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            },
          },
        },
        {
          props: {
            variant: 'ghost',
            color: 'inherit'
          },
          style: {
            backgroundColor: alpha('#000000', theme.palette.action.hoverOpacity),
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: alpha('#000000', theme.palette.action.selectedOpacity),
            },
          },
        },
        {
          props: {
            variant: 'ghost',
            color: 'secondary'
          },
          style: {
            backgroundColor: alpha(yellow[900], theme.palette.action.hoverOpacity),
            color: yellow[900],
            '&:hover': {
              backgroundColor: alpha(yellow[900], theme.palette.action.selectedOpacity),
            },
          },
        },
      ],
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: theme.spacing(2.5),
          paddingBottom: theme.spacing(2.5),
        },
      },
    },
  },
});

export default theme;
import { Column, ColumnContainer } from '../../components';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar, ListItemText,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

const ProductPageSkeleton = () => {
  return (
    <Grid2
      container
      columns={14}
      sx={{
        '--Grid-borderWidth': '1px',
        borderLeft: 'var(--Grid-borderWidth) solid',
        borderColor: 'divider',
        '& > div': {
          borderRight: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
        },
      }}
    >
      <Grid2 lg={3} md={4} sm={14} xs={14}>
        <Box p={3}>
          <Stack spacing={2}>
            <Skeleton>
              <Typography variant="subtitle1">
                Placeholder
              </Typography>
            </Skeleton>
            <Skeleton>
              <Typography variant="h4">
                Placeholder
              </Typography>
            </Skeleton>
          </Stack>
          <Skeleton>
            <Typography>
              Placeholder
            </Typography>
          </Skeleton>
          <Stack spacing={1.5} mt={3}>
            <Skeleton variant="rectangular" width="100%">
              <Button variant="ghost">
                Placeholder
              </Button>
            </Skeleton>
            <Skeleton variant="rectangular" width="100%">
              <Button variant="ghost">
                Placeholder
              </Button>
            </Skeleton>
          </Stack>
        </Box>
        <Divider />
        <Box p={3}>
          <Skeleton>
            <Typography variant="subtitle2">
              Placeholder
            </Typography>
            <Typography variant="caption">
              Placeholder
            </Typography>
          </Skeleton>
        </Box>
        <Divider />
        <Box p={3}>
          <Skeleton>
            <Typography variant="subtitle2">
              Placeholder
            </Typography>
            <Typography variant="caption">
              Placeholder
            </Typography>
          </Skeleton>
        </Box>
        <Divider />
        <Box p={3}>
          <Skeleton>
            <Typography variant="subtitle2">
              Placeholder
            </Typography>
            <Typography variant="caption">
              Placeholder
            </Typography>
          </Skeleton>
        </Box>
      </Grid2>
      <Grid2 lg={8} md={6} sm={14} xs={14}>
        <Box flexGrow={1}>
          <Box borderBottom="1px solid" borderColor="divider">
            <Tabs sx={{ px: 3 }}>
              <Tab label={<Skeleton width={60} />} />
              <Tab label={<Skeleton width={60} />} />
            </Tabs>
          </Box>
          <Box p={3}>
            <Skeleton variant="rectangular" height={600} />
          </Box>
          <Stack spacing={3} px={3}>
            <Skeleton>
              <Typography variant="h6">
                Placeholder Placeholder
              </Typography>
            </Skeleton>
          </Stack>
          <Skeleton variant="rectangular" height={504} sx={{ mt: 3 }} />
        </Box>
      </Grid2>
      <Grid2 lg={3} md={4} sm={14} xs={14}>
        <Box p={3}>
          <Skeleton sx={{ mb: 2 }}>
            <Typography variant="subtitle1">
              Placeholder
            </Typography>
          </Skeleton>
          <Skeleton variant="rectangular" width="100%">
            <TextField multiline minRows={2} size="small" />
          </Skeleton>
          <Stack direction="row" spacing={1} justifyContent="flex-end" mt={1}>
            <Skeleton>
              <Button>One</Button>
            </Skeleton>
            <Skeleton>
              <Button>Two</Button>
            </Skeleton>
          </Stack>
        </Box>
        <Divider />
        <List disablePadding sx={{ p: 3 }}>
          {Array.from({ length: 3 }).map((_, index) => (
            <ListItem key={index} disableGutters alignItems="flex-start">
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar>PH</Avatar>
                </Skeleton>
              </ListItemAvatar>
              <ListItemText
                primary={<Skeleton />}
                secondary={<Skeleton height={50} />}
              />
            </ListItem>
          ))}
        </List>
      </Grid2>
    </Grid2>
  );
};

export default ProductPageSkeleton;

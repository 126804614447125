import { Box, Typography, TypographyProps } from '@mui/material';
import React from 'react';
import { getPercentChange, toAbsDisplayNumber } from '../../utils';
import { DirectionIcon } from '../DirectionIcon';
import { SkeletonWrapper } from '../Skeleton';

export interface PercentChangeProps {
  initial: number;
  current: number;
  size?: string | number;
  typographyProps?: TypographyProps;
  loading?: boolean;
}

const PercentChange = ({
  initial,
  current,
  size,
  typographyProps,
  loading
}: PercentChangeProps) => {
  const percentChange = getPercentChange(initial, current);
  const displayNumber = toAbsDisplayNumber(percentChange);

  return (
    <SkeletonWrapper loading={loading ?? false}>
      <Box
        display="flex"
        alignItems="center"
        color={percentChange >= 0 ? 'success.main' : 'error.main'}
        minWidth={0}
      >
        <DirectionIcon change={percentChange} size={size} />
        <Typography {...typographyProps}>
          {displayNumber}%
        </Typography>
      </Box>
    </SkeletonWrapper>
  );
};

PercentChange.defaultProps = {
  size: 16,
  typographyProps: undefined,
  loading: false,
};

export default PercentChange;

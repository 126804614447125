import { Box, BoxProps, SkeletonProps } from '@mui/material';
import React from 'react';
import { SkeletonWrapper } from '../../../../components';

export interface ProductDataGridCellProps extends BoxProps {
  children: React.ReactNode;
  loading: boolean;
  skeletonProps?: SkeletonProps;
}

const ProductDataGridCell = ({
  children,
  loading,
  skeletonProps,
  ...boxProps
}: ProductDataGridCellProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      {...boxProps}
    >
      <SkeletonWrapper
        loading={loading}
        width="100%"
        {...skeletonProps}
      >
        {children}
      </SkeletonWrapper>
    </Box>
  );
};

export default ProductDataGridCell;

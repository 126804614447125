
const bukkitIdMap: Record<string, string> = {
  "AIR": "minecraft:air",
  "STONE": "minecraft:stone",
  "GRASS": "minecraft:grass",
  "DIRT": "minecraft:dirt",
  "COBBLESTONE": "minecraft:cobblestone",
  "WOOD": "minecraft:planks",
  "SAPLING": "minecraft:sapling",
  "BEDROCK": "minecraft:bedrock",
  "WATER": "minecraft:flowing_water",
  "STATIONARY_WATER": "minecraft:water",
  "LAVA": "minecraft:flowing_lava",
  "STATIONARY_LAVA": "minecraft:lava",
  "SAND": "minecraft:sand",
  "GRAVEL": "minecraft:gravel",
  "GOLD_ORE": "minecraft:gold_ore",
  "IRON_ORE": "minecraft:iron_ore",
  "COAL_ORE": "minecraft:coal_ore",
  "LOG": "minecraft:log",
  "LEAVES": "minecraft:leaves",
  "SPONGE": "minecraft:sponge",
  "GLASS": "minecraft:glass",
  "LAPIS_ORE": "minecraft:lapis_ore",
  "LAPIS_BLOCK": "minecraft:lapis_block",
  "DISPENSER": "minecraft:dispenser",
  "SANDSTONE": "minecraft:sandstone",
  "NOTE_BLOCK": "minecraft:noteblock",
  "BED_BLOCK": "minecraft:bed",
  "POWERED_RAIL": "minecraft:golden_rail",
  "DETECTOR_RAIL": "minecraft:detector_rail",
  "PISTON_STICKY_BASE": "minecraft:sticky_piston",
  "WEB": "minecraft:web",
  "LONG_GRASS": "minecraft:tallgrass",
  "DEAD_BUSH": "minecraft:deadbush",
  "PISTON_BASE": "minecraft:piston",
  "PISTON_EXTENSION": "minecraft:piston_head",
  "WOOL": "minecraft:wool",
  "PISTON_MOVING_PIECE": "minecraft:piston_extension",
  "YELLOW_FLOWER": "minecraft:yellow_flower",
  "RED_ROSE": "minecraft:red_flower",
  "BROWN_MUSHROOM": "minecraft:brown_mushroom",
  "RED_MUSHROOM": "minecraft:red_mushroom",
  "GOLD_BLOCK": "minecraft:gold_block",
  "IRON_BLOCK": "minecraft:iron_block",
  "DOUBLE_STEP": "minecraft:double_stone_slab",
  "STEP": "minecraft:stone_slab",
  "BRICK": "minecraft:brick_block",
  "TNT": "minecraft:tnt",
  "BOOKSHELF": "minecraft:bookshelf",
  "MOSSY_COBBLESTONE": "minecraft:mossy_cobblestone",
  "OBSIDIAN": "minecraft:obsidian",
  "TORCH": "minecraft:torch",
  "FIRE": "minecraft:fire",
  "MOB_SPAWNER": "minecraft:mob_spawner",
  "WOOD_STAIRS": "minecraft:oak_stairs",
  "CHEST": "minecraft:chest",
  "REDSTONE_WIRE": "minecraft:redstone_wire",
  "DIAMOND_ORE": "minecraft:diamond_ore",
  "DIAMOND_BLOCK": "minecraft:diamond_block",
  "WORKBENCH": "minecraft:crafting_table",
  "CROPS": "minecraft:wheat",
  "SOIL": "minecraft:farmland",
  "FURNACE": "minecraft:furnace",
  "BURNING_FURNACE": "minecraft:lit_furnace",
  "SIGN_POST": "minecraft:standing_sign",
  "WOODEN_DOOR": "minecraft:wooden_door",
  "LADDER": "minecraft:ladder",
  "RAILS": "minecraft:rail",
  "COBBLESTONE_STAIRS": "minecraft:stone_stairs",
  "WALL_SIGN": "minecraft:wall_sign",
  "LEVER": "minecraft:lever",
  "STONE_PLATE": "minecraft:stone_pressure_plate",
  "IRON_DOOR_BLOCK": "minecraft:iron_door",
  "WOOD_PLATE": "minecraft:wooden_pressure_plate",
  "REDSTONE_ORE": "minecraft:redstone_ore",
  "GLOWING_REDSTONE_ORE": "minecraft:lit_redstone_ore",
  "REDSTONE_TORCH_OFF": "minecraft:unlit_redstone_torch",
  "REDSTONE_TORCH_ON": "minecraft:redstone_torch",
  "STONE_BUTTON": "minecraft:stone_button",
  "SNOW": "minecraft:snow_layer",
  "ICE": "minecraft:ice",
  "SNOW_BLOCK": "minecraft:snow",
  "CACTUS": "minecraft:cactus",
  "CLAY": "minecraft:clay",
  "SUGAR_CANE_BLOCK": "minecraft:reeds",
  "JUKEBOX": "minecraft:jukebox",
  "FENCE": "minecraft:fence",
  "PUMPKIN": "minecraft:pumpkin",
  "NETHERRACK": "minecraft:netherrack",
  "SOUL_SAND": "minecraft:soul_sand",
  "GLOWSTONE": "minecraft:glowstone",
  "PORTAL": "minecraft:portal",
  "JACK_O_LANTERN": "minecraft:lit_pumpkin",
  "CAKE_BLOCK": "minecraft:cake",
  "DIODE_BLOCK_OFF": "minecraft:unpowered_repeater",
  "DIODE_BLOCK_ON": "minecraft:powered_repeater",
  "STAINED_GLASS": "minecraft:stained_glass",
  "TRAP_DOOR": "minecraft:trapdoor",
  "MONSTER_EGGS": "minecraft:monster_egg",
  "SMOOTH_BRICK": "minecraft:stonebrick",
  "HUGE_MUSHROOM_1": "minecraft:brown_mushroom_block",
  "HUGE_MUSHROOM_2": "minecraft:red_mushroom_block",
  "IRON_FENCE": "minecraft:iron_bars",
  "THIN_GLASS": "minecraft:glass_pane",
  "MELON_BLOCK": "minecraft:melon_block",
  "PUMPKIN_STEM": "minecraft:pumpkin_stem",
  "MELON_STEM": "minecraft:melon_stem",
  "VINE": "minecraft:vine",
  "FENCE_GATE": "minecraft:fence_gate",
  "BRICK_STAIRS": "minecraft:brick_stairs",
  "SMOOTH_STAIRS": "minecraft:stone_brick_stairs",
  "MYCEL": "minecraft:mycelium",
  "WATER_LILY": "minecraft:waterlily",
  "NETHER_BRICK": "minecraft:nether_brick",
  "NETHER_FENCE": "minecraft:nether_brick_fence",
  "NETHER_BRICK_STAIRS": "minecraft:nether_brick_stairs",
  "NETHER_WARTS": "minecraft:nether_wart",
  "ENCHANTMENT_TABLE": "minecraft:enchanting_table",
  "BREWING_STAND": "minecraft:brewing_stand",
  "CAULDRON": "minecraft:cauldron",
  "ENDER_PORTAL": "minecraft:end_portal",
  "ENDER_PORTAL_FRAME": "minecraft:end_portal_frame",
  "ENDER_STONE": "minecraft:end_stone",
  "DRAGON_EGG": "minecraft:dragon_egg",
  "REDSTONE_LAMP_OFF": "minecraft:redstone_lamp",
  "REDSTONE_LAMP_ON": "minecraft:lit_redstone_lamp",
  "WOOD_DOUBLE_STEP": "minecraft:double_wooden_slab",
  "WOOD_STEP": "minecraft:wooden_slab",
  "COCOA": "minecraft:cocoa",
  "SANDSTONE_STAIRS": "minecraft:sandstone_stairs",
  "EMERALD_ORE": "minecraft:emerald_ore",
  "ENDER_CHEST": "minecraft:ender_chest",
  "TRIPWIRE_HOOK": "minecraft:tripwire_hook",
  "TRIPWIRE": "minecraft:tripwire",
  "EMERALD_BLOCK": "minecraft:emerald_block",
  "SPRUCE_WOOD_STAIRS": "minecraft:spruce_stairs",
  "BIRCH_WOOD_STAIRS": "minecraft:birch_stairs",
  "JUNGLE_WOOD_STAIRS": "minecraft:jungle_stairs",
  "COMMAND": "minecraft:command_block",
  "BEACON": "minecraft:beacon",
  "COBBLE_WALL": "minecraft:cobblestone_wall",
  "FLOWER_POT": "minecraft:flower_pot",
  "CARROT": "minecraft:carrots",
  "POTATO": "minecraft:potatoes",
  "WOOD_BUTTON": "minecraft:wooden_button",
  "SKULL": "minecraft:skull",
  "ANVIL": "minecraft:anvil",
  "TRAPPED_CHEST": "minecraft:trapped_chest",
  "GOLD_PLATE": "minecraft:light_weighted_pressure_plate",
  "IRON_PLATE": "minecraft:heavy_weighted_pressure_plate",
  "REDSTONE_COMPARATOR_OFF": "minecraft:unpowered_comparator",
  "REDSTONE_COMPARATOR_ON": "minecraft:powered_comparator",
  "DAYLIGHT_DETECTOR": "minecraft:daylight_detector",
  "REDSTONE_BLOCK": "minecraft:redstone_block",
  "QUARTZ_ORE": "minecraft:quartz_ore",
  "HOPPER": "minecraft:hopper",
  "QUARTZ_BLOCK": "minecraft:quartz_block",
  "QUARTZ_STAIRS": "minecraft:quartz_stairs",
  "ACTIVATOR_RAIL": "minecraft:activator_rail",
  "DROPPER": "minecraft:dropper",
  "STAINED_CLAY": "minecraft:stained_hardened_clay",
  "STAINED_GLASS_PANE": "minecraft:stained_glass_pane",
  "LEAVES_2": "minecraft:leaves2",
  "LOG_2": "minecraft:log2",
  "ACACIA_STAIRS": "minecraft:acacia_stairs",
  "DARK_OAK_STAIRS": "minecraft:dark_oak_stairs",
  "SLIME_BLOCK": "minecraft:slime",
  "BARRIER": "minecraft:barrier",
  "IRON_TRAPDOOR": "minecraft:iron_trapdoor",
  "PRISMARINE": "minecraft:prismarine",
  "SEA_LANTERN": "minecraft:sea_lantern",
  "HAY_BLOCK": "minecraft:hay_block",
  "CARPET": "minecraft:carpet",
  "HARD_CLAY": "minecraft:hardened_clay",
  "COAL_BLOCK": "minecraft:coal_block",
  "PACKED_ICE": "minecraft:packed_ice",
  "DOUBLE_PLANT": "minecraft:double_plant",
  "STANDING_BANNER": "minecraft:standing_banner",
  "WALL_BANNER": "minecraft:wall_banner",
  "DAYLIGHT_DETECTOR_INVERTED": "minecraft:daylight_detector_inverted",
  "RED_SANDSTONE": "minecraft:red_sandstone",
  "RED_SANDSTONE_STAIRS": "minecraft:red_sandstone_stairs",
  "DOUBLE_STONE_SLAB2": "minecraft:double_stone_slab2",
  "STONE_SLAB2": "minecraft:stone_slab2",
  "SPRUCE_FENCE_GATE": "minecraft:spruce_fence_gate",
  "BIRCH_FENCE_GATE": "minecraft:birch_fence_gate",
  "JUNGLE_FENCE_GATE": "minecraft:jungle_fence_gate",
  "DARK_OAK_FENCE_GATE": "minecraft:dark_oak_fence_gate",
  "ACACIA_FENCE_GATE": "minecraft:acacia_fence_gate",
  "SPRUCE_FENCE": "minecraft:spruce_fence",
  "BIRCH_FENCE": "minecraft:birch_fence",
  "JUNGLE_FENCE": "minecraft:jungle_fence",
  "DARK_OAK_FENCE": "minecraft:dark_oak_fence",
  "ACACIA_FENCE": "minecraft:acacia_fence",
  "SPRUCE_DOOR": "minecraft:spruce_door",
  "BIRCH_DOOR": "minecraft:birch_door",
  "JUNGLE_DOOR": "minecraft:jungle_door",
  "ACACIA_DOOR": "minecraft:acacia_door",
  "DARK_OAK_DOOR": "minecraft:dark_oak_door",
  "END_ROD": "minecraft:end_rod",
  "CHORUS_PLANT": "minecraft:chorus_plant",
  "CHORUS_FLOWER": "minecraft:chorus_flower",
  "PURPUR_BLOCK": "minecraft:purpur_block",
  "PURPUR_PILLAR": "minecraft:purpur_pillar",
  "PURPUR_STAIRS": "minecraft:purpur_stairs",
  "PURPUR_DOUBLE_SLAB": "minecraft:purpur_double_slab",
  "PURPUR_SLAB": "minecraft:purpur_slab",
  "END_BRICKS": "minecraft:end_bricks",
  "BEETROOT_BLOCK": "minecraft:beetroots",
  "GRASS_PATH": "minecraft:grass_path",
  "END_GATEWAY": "minecraft:end_gateway",
  "COMMAND_REPEATING": "minecraft:repeating_command_block",
  "COMMAND_CHAIN": "minecraft:chain_command_block",
  "FROSTED_ICE": "minecraft:frosted_ice",
  "MAGMA": "minecraft:magma",
  "NETHER_WART_BLOCK": "minecraft:nether_wart_block",
  "RED_NETHER_BRICK": "minecraft:red_nether_brick",
  "BONE_BLOCK": "minecraft:bone_block",
  "STRUCTURE_VOID": "minecraft:structure_void",
  "OBSERVER": "minecraft:observer",
  "WHITE_SHULKER_BOX": "minecraft:white_shulker_box",
  "ORANGE_SHULKER_BOX": "minecraft:orange_shulker_box",
  "MAGENTA_SHULKER_BOX": "minecraft:magenta_shulker_box",
  "LIGHT_BLUE_SHULKER_BOX": "minecraft:light_blue_shulker_box",
  "YELLOW_SHULKER_BOX": "minecraft:yellow_shulker_box",
  "LIME_SHULKER_BOX": "minecraft:lime_shulker_box",
  "PINK_SHULKER_BOX": "minecraft:pink_shulker_box",
  "GRAY_SHULKER_BOX": "minecraft:gray_shulker_box",
  "SILVER_SHULKER_BOX": "minecraft:silver_shulker_box",
  "CYAN_SHULKER_BOX": "minecraft:cyan_shulker_box",
  "PURPLE_SHULKER_BOX": "minecraft:purple_shulker_box",
  "BLUE_SHULKER_BOX": "minecraft:blue_shulker_box",
  "BROWN_SHULKER_BOX": "minecraft:brown_shulker_box",
  "GREEN_SHULKER_BOX": "minecraft:green_shulker_box",
  "RED_SHULKER_BOX": "minecraft:red_shulker_box",
  "BLACK_SHULKER_BOX": "minecraft:black_shulker_box",
  "WHITE_GLAZED_TERRACOTTA": "minecraft:white_glazed_terracotta",
  "ORANGE_GLAZED_TERRACOTTA": "minecraft:orange_glazed_terracotta",
  "MAGENTA_GLAZED_TERRACOTTA": "minecraft:magenta_glazed_terracotta",
  "LIGHT_BLUE_GLAZED_TERRACOTTA": "minecraft:light_blue_glazed_terracotta",
  "YELLOW_GLAZED_TERRACOTTA": "minecraft:yellow_glazed_terracotta",
  "LIME_GLAZED_TERRACOTTA": "minecraft:lime_glazed_terracotta",
  "PINK_GLAZED_TERRACOTTA": "minecraft:pink_glazed_terracotta",
  "GRAY_GLAZED_TERRACOTTA": "minecraft:gray_glazed_terracotta",
  "SILVER_GLAZED_TERRACOTTA": "minecraft:silver_glazed_terracotta",
  "CYAN_GLAZED_TERRACOTTA": "minecraft:cyan_glazed_terracotta",
  "PURPLE_GLAZED_TERRACOTTA": "minecraft:purple_glazed_terracotta",
  "BLUE_GLAZED_TERRACOTTA": "minecraft:blue_glazed_terracotta",
  "BROWN_GLAZED_TERRACOTTA": "minecraft:brown_glazed_terracotta",
  "GREEN_GLAZED_TERRACOTTA": "minecraft:green_glazed_terracotta",
  "RED_GLAZED_TERRACOTTA": "minecraft:red_glazed_terracotta",
  "BLACK_GLAZED_TERRACOTTA": "minecraft:black_glazed_terracotta",
  "CONCRETE": "minecraft:concrete",
  "CONCRETE_POWDER": "minecraft:concrete_powder",
  "STRUCTURE_BLOCK": "minecraft:structure_block",
  "IRON_SPADE": "minecraft:iron_shovel",
  "IRON_PICKAXE": "minecraft:iron_pickaxe",
  "IRON_AXE": "minecraft:iron_axe",
  "FLINT_AND_STEEL": "minecraft:flint_and_steel",
  "APPLE": "minecraft:apple",
  "BOW": "minecraft:bow",
  "ARROW": "minecraft:arrow",
  "COAL": "minecraft:coal",
  "DIAMOND": "minecraft:diamond",
  "IRON_INGOT": "minecraft:iron_ingot",
  "GOLD_INGOT": "minecraft:gold_ingot",
  "IRON_SWORD": "minecraft:iron_sword",
  "WOOD_SWORD": "minecraft:wooden_sword",
  "WOOD_SPADE": "minecraft:wooden_shovel",
  "WOOD_PICKAXE": "minecraft:wooden_pickaxe",
  "WOOD_AXE": "minecraft:wooden_axe",
  "STONE_SWORD": "minecraft:stone_sword",
  "STONE_SPADE": "minecraft:stone_shovel",
  "STONE_PICKAXE": "minecraft:stone_pickaxe",
  "STONE_AXE": "minecraft:stone_axe",
  "DIAMOND_SWORD": "minecraft:diamond_sword",
  "DIAMOND_SPADE": "minecraft:diamond_shovel",
  "DIAMOND_PICKAXE": "minecraft:diamond_pickaxe",
  "DIAMOND_AXE": "minecraft:diamond_axe",
  "STICK": "minecraft:stick",
  "BOWL": "minecraft:bowl",
  "MUSHROOM_SOUP": "minecraft:mushroom_stew",
  "GOLD_SWORD": "minecraft:golden_sword",
  "GOLD_SPADE": "minecraft:golden_shovel",
  "GOLD_PICKAXE": "minecraft:golden_pickaxe",
  "GOLD_AXE": "minecraft:golden_axe",
  "STRING": "minecraft:string",
  "FEATHER": "minecraft:feather",
  "SULPHUR": "minecraft:gunpowder",
  "WOOD_HOE": "minecraft:wooden_hoe",
  "STONE_HOE": "minecraft:stone_hoe",
  "IRON_HOE": "minecraft:iron_hoe",
  "DIAMOND_HOE": "minecraft:diamond_hoe",
  "GOLD_HOE": "minecraft:golden_hoe",
  "SEEDS": "minecraft:wheat_seeds",
  "WHEAT": "minecraft:wheat",
  "BREAD": "minecraft:bread",
  "LEATHER_HELMET": "minecraft:leather_helmet",
  "LEATHER_CHESTPLATE": "minecraft:leather_chestplate",
  "LEATHER_LEGGINGS": "minecraft:leather_leggings",
  "LEATHER_BOOTS": "minecraft:leather_boots",
  "CHAINMAIL_HELMET": "minecraft:chainmail_helmet",
  "CHAINMAIL_CHESTPLATE": "minecraft:chainmail_chestplate",
  "CHAINMAIL_LEGGINGS": "minecraft:chainmail_leggings",
  "CHAINMAIL_BOOTS": "minecraft:chainmail_boots",
  "IRON_HELMET": "minecraft:iron_helmet",
  "IRON_CHESTPLATE": "minecraft:iron_chestplate",
  "IRON_LEGGINGS": "minecraft:iron_leggings",
  "IRON_BOOTS": "minecraft:iron_boots",
  "DIAMOND_HELMET": "minecraft:diamond_helmet",
  "DIAMOND_CHESTPLATE": "minecraft:diamond_chestplate",
  "DIAMOND_LEGGINGS": "minecraft:diamond_leggings",
  "DIAMOND_BOOTS": "minecraft:diamond_boots",
  "GOLD_HELMET": "minecraft:golden_helmet",
  "GOLD_CHESTPLATE": "minecraft:golden_chestplate",
  "GOLD_LEGGINGS": "minecraft:golden_leggings",
  "GOLD_BOOTS": "minecraft:golden_boots",
  "FLINT": "minecraft:flint",
  "PORK": "minecraft:porkchop",
  "GRILLED_PORK": "minecraft:cooked_porkchop",
  "PAINTING": "minecraft:painting",
  "GOLDEN_APPLE": "minecraft:golden_apple",
  "SIGN": "minecraft:sign",
  "WOOD_DOOR": "minecraft:wooden_door",
  "BUCKET": "minecraft:bucket",
  "WATER_BUCKET": "minecraft:water_bucket",
  "LAVA_BUCKET": "minecraft:lava_bucket",
  "MINECART": "minecraft:minecart",
  "SADDLE": "minecraft:saddle",
  "IRON_DOOR": "minecraft:iron_door",
  "REDSTONE": "minecraft:redstone",
  "SNOW_BALL": "minecraft:snowball",
  "BOAT": "minecraft:boat",
  "LEATHER": "minecraft:leather",
  "MILK_BUCKET": "minecraft:milk_bucket",
  "CLAY_BRICK": "minecraft:brick",
  "CLAY_BALL": "minecraft:clay_ball",
  "SUGAR_CANE": "minecraft:reeds",
  "PAPER": "minecraft:paper",
  "BOOK": "minecraft:book",
  "SLIME_BALL": "minecraft:slime_ball",
  "STORAGE_MINECART": "minecraft:chest_minecart",
  "POWERED_MINECART": "minecraft:furnace_minecart",
  "EGG": "minecraft:egg",
  "COMPASS": "minecraft:compass",
  "FISHING_ROD": "minecraft:fishing_rod",
  "WATCH": "minecraft:clock",
  "GLOWSTONE_DUST": "minecraft:glowstone_dust",
  "RAW_FISH": "minecraft:fish",
  "COOKED_FISH": "minecraft:cooked_fish",
  "INK_SACK": "minecraft:dye",
  "BONE": "minecraft:bone",
  "SUGAR": "minecraft:sugar",
  "CAKE": "minecraft:cake",
  "BED": "minecraft:bed",
  "DIODE": "minecraft:repeater",
  "COOKIE": "minecraft:cookie",
  "MAP": "minecraft:filled_map",
  "SHEARS": "minecraft:shears",
  "MELON": "minecraft:melon",
  "PUMPKIN_SEEDS": "minecraft:pumpkin_seeds",
  "MELON_SEEDS": "minecraft:melon_seeds",
  "RAW_BEEF": "minecraft:beef",
  "COOKED_BEEF": "minecraft:cooked_beef",
  "RAW_CHICKEN": "minecraft:chicken",
  "COOKED_CHICKEN": "minecraft:cooked_chicken",
  "ROTTEN_FLESH": "minecraft:rotten_flesh",
  "ENDER_PEARL": "minecraft:ender_pearl",
  "BLAZE_ROD": "minecraft:blaze_rod",
  "GHAST_TEAR": "minecraft:ghast_tear",
  "GOLD_NUGGET": "minecraft:gold_nugget",
  "NETHER_STALK": "minecraft:nether_wart",
  "POTION": "minecraft:potion",
  "GLASS_BOTTLE": "minecraft:glass_bottle",
  "SPIDER_EYE": "minecraft:spider_eye",
  "FERMENTED_SPIDER_EYE": "minecraft:fermented_spider_eye",
  "BLAZE_POWDER": "minecraft:blaze_powder",
  "MAGMA_CREAM": "minecraft:magma_cream",
  "BREWING_STAND_ITEM": "minecraft:brewing_stand",
  "CAULDRON_ITEM": "minecraft:cauldron",
  "EYE_OF_ENDER": "minecraft:ender_eye",
  "SPECKLED_MELON": "minecraft:speckled_melon",
  "MONSTER_EGG": "minecraft:spawn_egg",
  "EXP_BOTTLE": "minecraft:experience_bottle",
  "FIREBALL": "minecraft:fire_charge",
  "BOOK_AND_QUILL": "minecraft:writable_book",
  "WRITTEN_BOOK": "minecraft:written_book",
  "EMERALD": "minecraft:emerald",
  "ITEM_FRAME": "minecraft:item_frame",
  "FLOWER_POT_ITEM": "minecraft:flower_pot",
  "CARROT_ITEM": "minecraft:carrot",
  "POTATO_ITEM": "minecraft:potato",
  "BAKED_POTATO": "minecraft:baked_potato",
  "POISONOUS_POTATO": "minecraft:poisonous_potato",
  "EMPTY_MAP": "minecraft:map",
  "GOLDEN_CARROT": "minecraft:golden_carrot",
  "SKULL_ITEM": "minecraft:skull",
  "CARROT_STICK": "minecraft:carrot_on_a_stick",
  "NETHER_STAR": "minecraft:nether_star",
  "PUMPKIN_PIE": "minecraft:pumpkin_pie",
  "FIREWORK": "minecraft:fireworks",
  "FIREWORK_CHARGE": "minecraft:firework_charge",
  "ENCHANTED_BOOK": "minecraft:enchanted_book",
  "REDSTONE_COMPARATOR": "minecraft:comparator",
  "NETHER_BRICK_ITEM": "minecraft:netherbrick",
  "QUARTZ": "minecraft:quartz",
  "EXPLOSIVE_MINECART": "minecraft:tnt_minecart",
  "HOPPER_MINECART": "minecraft:hopper_minecart",
  "PRISMARINE_SHARD": "minecraft:prismarine_shard",
  "PRISMARINE_CRYSTALS": "minecraft:prismarine_crystals",
  "RABBIT": "minecraft:rabbit",
  "COOKED_RABBIT": "minecraft:cooked_rabbit",
  "RABBIT_STEW": "minecraft:rabbit_stew",
  "RABBIT_FOOT": "minecraft:rabbit_foot",
  "RABBIT_HIDE": "minecraft:rabbit_hide",
  "ARMOR_STAND": "minecraft:armor_stand",
  "IRON_BARDING": "minecraft:iron_horse_armor",
  "GOLD_BARDING": "minecraft:golden_horse_armor",
  "DIAMOND_BARDING": "minecraft:diamond_horse_armor",
  "LEASH": "minecraft:lead",
  "NAME_TAG": "minecraft:name_tag",
  "COMMAND_MINECART": "minecraft:command_block_minecart",
  "MUTTON": "minecraft:mutton",
  "COOKED_MUTTON": "minecraft:cooked_mutton",
  "BANNER": "minecraft:banner",
  "END_CRYSTAL": "minecraft:end_crystal",
  "SPRUCE_DOOR_ITEM": "minecraft:spruce_door",
  "BIRCH_DOOR_ITEM": "minecraft:birch_door",
  "JUNGLE_DOOR_ITEM": "minecraft:jungle_door",
  "ACACIA_DOOR_ITEM": "minecraft:acacia_door",
  "DARK_OAK_DOOR_ITEM": "minecraft:dark_oak_door",
  "CHORUS_FRUIT": "minecraft:chorus_fruit",
  "CHORUS_FRUIT_POPPED": "minecraft:chorus_fruit_popped",
  "BEETROOT": "minecraft:beetroot",
  "BEETROOT_SEEDS": "minecraft:beetroot_seeds",
  "BEETROOT_SOUP": "minecraft:beetroot_soup",
  "DRAGONS_BREATH": "minecraft:dragon_breath",
  "SPLASH_POTION": "minecraft:splash_potion",
  "SPECTRAL_ARROW": "minecraft:spectral_arrow",
  "TIPPED_ARROW": "minecraft:tipped_arrow",
  "LINGERING_POTION": "minecraft:lingering_potion",
  "SHIELD": "minecraft:shield",
  "ELYTRA": "minecraft:elytra",
  "BOAT_SPRUCE": "minecraft:spruce_boat",
  "BOAT_BIRCH": "minecraft:birch_boat",
  "BOAT_JUNGLE": "minecraft:jungle_boat",
  "BOAT_ACACIA": "minecraft:acacia_boat",
  "BOAT_DARK_OAK": "minecraft:dark_oak_boat",
  "TOTEM": "minecraft:totem_of_undying",
  "SHULKER_SHELL": "minecraft:shulker_shell",
  "IRON_NUGGET": "minecraft:iron_nugget",
  "KNOWLEDGE_BOOK": "minecraft:knowledge_book",
  "GOLD_RECORD": "minecraft:record_13",
  "GREEN_RECORD": "minecraft:record_cat",
  "RECORD_3": "minecraft:record_blocks",
  "RECORD_4": "minecraft:record_chirp",
  "RECORD_5": "minecraft:record_far",
  "RECORD_6": "minecraft:record_mall",
  "RECORD_7": "minecraft:record_mellohi",
  "RECORD_8": "minecraft:record_stal",
  "RECORD_9": "minecraft:record_strad",
  "RECORD_10": "minecraft:record_ward",
  "RECORD_11": "minecraft:record_11",
  "RECORD_12": "minecraft:record_wait"
};

export default bukkitIdMap;

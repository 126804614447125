import { LoaderFunction, useRouteLoaderData } from 'react-router-dom';
import { getItemCount } from '../../features/items';
import { getProductCount } from '../../features/products';
import { getAuctionCount } from '../../features/auctions/api/useGetAuctionCount';
import { getNewestStatistic, getOldestStatistic, Statistic } from '../../features/statistics';
import { LoaderData } from '../../types';

export interface HomeLoaderData {
  itemCount: number;
  productCount: number;
  auctionCount: number;
  productBuyCapNewest: Statistic;
  productBuyCapOldest: Statistic;
  productSellCapNewest: Statistic;
  productSellCapOldest: Statistic;
}

export const homeLoader = (async (): Promise<HomeLoaderData> => {
  const [
    itemCount,
    productCount,
    auctionCount,
    productBuyCapNewest,
    productBuyCapOldest,
    productSellCapNewest,
    productSellCapOldest,
  ] = await Promise.all([
    getItemCount(),
    getProductCount(),
    getAuctionCount(),
    getNewestStatistic('product-buy-cap'),
    getOldestStatistic('product-buy-cap'),
    getNewestStatistic('product-sell-cap'),
    getOldestStatistic('product-sell-cap')
  ]);

  return {
    itemCount: itemCount,
    productCount: productCount,
    auctionCount: auctionCount,
    productBuyCapNewest: productBuyCapNewest,
    productBuyCapOldest: productBuyCapOldest,
    productSellCapNewest: productSellCapNewest,
    productSellCapOldest: productSellCapOldest,
  };
}) satisfies LoaderFunction;

const useHomeLoaderData = (routeId: string) => useRouteLoaderData(routeId) as LoaderData<typeof homeLoader>;

export default useHomeLoaderData;

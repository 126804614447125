import { IconStar, IconStarFilled } from '@tabler/icons-react';
import { yellow } from '@mui/material/colors';

export interface FavoriteIconProps {
  favorite?: boolean;
  size?: string | number;
}

const FavoriteIcon = ({ favorite, size }: FavoriteIconProps) => {
  return favorite
    ? <IconStarFilled size={size} color={yellow[700]} />
    : <IconStar size={size} />;
};

FavoriteIcon.defaultProps = {
  size: 16,
};

export default FavoriteIcon;

import axios from 'axios';

const apiClient = axios.create({
  // baseURL: 'http://localhost:8080/api',
  baseURL: '/api',
  headers: {
    'Content-type': 'application/json',
  },
});

export default apiClient;
